.ant-tag-orange,.ant-tag-red,.ant-tag-green,.ant-tag-blue,.ant-tag-purple,.ant-tag-geekblue,.ant-tag-gold,.ant-tag-cyan {
  color: #fff;
}
.ant-tag-orange {
  background: @orange;
  border-color: @orange;
}
.ant-tag-gold {
  background: @gold;
  border-color: @gold;
}
.ant-tag-red {
  background: @red;
  border-color: @red;
}
.ant-tag-green {
  background: @green;
  border-color: @green;
}
.ant-tag-blue {
  background: @blue;
  border-color: @blue;
}
.ant-tag-cyan {
  background: @cyan;
  border-color: @cyan;
}
.ant-tag-geekblue {
  background: @geekblue;
  border-color: @geekblue;
}
.ant-tag-purple{
  background: @purple;
  border-color: @purple;
}
