.layout-wrapper {
    padding: 24px;
    @media @mobile {
        padding: 56px 0 0;
    }
}

.content-wrapper {
    background: #fff;
    @media @mobile {
        min-height: 100%;
    }
}
