.news-text {
  padding: 15px;
}

@media @desktop {
  .news-date {
    text-align: center;
  }
}

@media @mobile {
  &.table-mobile.table-news {
    padding: 0;
    .ant-table {
      background: transparent;
      colgroup {
        display: none;
      }
      tr.ant-table-row {
        background: @main-color;
        border-radius: 8px;
        margin-bottom: 8px;
        padding: 8px 12px;
        a {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #fff;
        }
        &:hover > td {
          background: transparent;
        }
        .ql-viewer {
          margin-top: 8px;
          font-size: 14px;
          line-height: 16px;
          color: #fff;
        }
        .news-date {
          font-size: 12px;
          line-height: 14px;
          color: #8F8F8F;
        }
      }
    }
  }
}
