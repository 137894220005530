.date-element {
    white-space: nowrap;
}

.product-element, .address-element, .price-element, .shop-element {
    .anticon {
        margin-right: 4px;
    }

    @media @desktop {
        .anticon {
            display: none;
        }
    }
}
