.ant-table {
    width: 100%;
    border-top: 1px solid #e9e9e9;
    color: rgba(0,0,0,.65);

    &, table {
        border-radius: 0;
    }
    table {
        @media @mobile {
            width: 100% !important;
        }
    }
    .ant-table-tbody {
        .table-row-odd .ant-table-cell {
            background-color: rgb(251, 251, 251);
        }
    }
    .ant-table-cell {
        &.border-left {
            border-left: 2px solid #f0f0f0!important;
        }
        &.border-right {
            border-right: 1px solid #f0f0f0!important;
        }
        &.cell-loading {
            background-color: #eee;
            color: #aaa;
        }
        textarea.ant-input-sm {
            padding: 3px 7px;
        }
    }
    .ant-table-container table {
        th.ant-table-cell:first-child, th.ant-table-cell:last-child {
            border-radius: 0
        }
    }

    .td-text {
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .error-message {
        color: @red;
    }
}

.plan-table {
    .ant-table-row-level-0 {
        .ant-table-cell-with-append {
            color: #293649;
        }
    }
    .ant-table-row-level-1 {
        .ant-table-cell-with-append {
            div {
                span {
                    font-weight: 500;
                    color: #333;
                }
            }
        }
    }
    .ant-badge-status-dot {
        border: 1px solid @primary;
        width: 16px;
        height: 16px;
    }
}

.shop-plans-promoter-link {
    font-weight: 500;
}

.ant-table-thead > tr > th {
    white-space: nowrap;
    font-weight: normal;
    font-size: 13px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    word-break: normal;
}

.th-multi .ant-table-thead > tr > th {
    padding: 5px 8px;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}

.th-multi .ant-table-thead > tr > th:last-child {
    border-right: 0;
}

.ant-table-wrapper:not(.row-table-selection) tr > {
    td,th{
        &:first-child{
            padding-left: 15px;
        }
        &:last-child{
            padding-right: 15px;
        }
    }
}

.timetable .ant-table-wrapper:not(.row-table-selection) tr >{
    td,th{
        &:last-child{
            padding-right: inherit;
        }
    }
}

.ant-table-pagination.ant-pagination {
    margin-right: 16px;
}

.table-no-header {
    .ant-table-thead th {
        display: none;
    }
}

.formatted-price {
    white-space: nowrap;
}

.ant-table-wrapper {
    .ant-spin {
        .ant-spin-dot {
            top: 80px !important;
        }
    }
}

.ant-table-tbody {
    & > tr.ant-table-row-selected {
        &:hover > td,
        & > td {
            background: #e3e7ed;
        }
    }
}

.shop-plans-table {

    .timetable-columns {
        position: relative;
        &.show-timetable-column {
            padding-top: 16px;
        }
        .btn-timetable {
            position: absolute;
            left: -8px;
            font-size: 12px;
            border: 1px solid #354860;
            cursor: pointer;
            z-index: 1;
            background-color: #fafafa;
            color: #354860;
            font-weight: 500;
            height: 20px;
        }
        .show-timetable {
            top: -6px;
            right: -9px;
        }
        .hide-timetable {
            top: -17px;
        }
    }

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead.ant-table-thead {
        tr:first-child {
            th {
                &:nth-child(1),
                &:nth-child(2) {
                    position: relative;
                    &:before {
                        content: '';
                        width: 100%;
                        position: absolute;
                        top: -20px;
                        left: 0;
                        right: 0;
                        z-index: 1;
                        background-color: #fff!important;
                        height: 20px;
                    }
                }
            }
        }
    }

    .shop-plans-promoter-link {
        display: inline-block;
        color: #364760;
        border-bottom: 1px dashed #364760;
        cursor: pointer;
        span {
            color: #364760!important;
        }
    }

    .shop-plans-timetable-day-column {
        padding: 0;

        .shop-plans-timetable-day-cell {
            cursor: pointer;
            width: 100%;
            padding: 19px 12px;
            color: #fff;
            white-space: nowrap;

            &.filled {
                padding: 16px 0;
                text-align: center;
            }

            &.has-comment {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    border: 10px solid transparent;
                    border-top: 10px solid rgba(0, 0, 0, .3);
                    border-right: 10px solid rgba(0, 0, 0, .3);
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    th.weekend-column {
        background-color: #EEEEEE !important;

        .day-of-week {
            color: #D47774;
        }
    }

    .day-of-week {
        font-size: 12px;
        position: relative;
        top: 4px;
    }

    .day-of-num {
        margin-top: 12px;
        position: relative;
        top: 7px;
    }

    .ant-table-expanded-row {
        & > td {
            padding: 0 !important;
            border-bottom: 0;
        }
        .ant-table {
            border: 0;
        }
        table {
            border: 0 !important;
        }
        .ant-table-container {
            border-left: 0;
        }
        tr.ant-table-row td.ant-table-cell:last-child {
            border-right: 0;
        }
    }

    table {
        border-top: 0;
        .ant-table-content {
            padding: 0;
        }
    }
    .ant-table-content {
        padding-top: 20px;
    }
    .ant-table {
        border-top: 0;
    }
    .ant-input-number {
        width: 100%;
        border-radius: 3px;
        min-height: 28px;
        margin: -5px 0;
        input {
            text-align: center;
            height: 28px;
        }
    }
}

.time-report-table {
    .filters-bar {
        position: relative;

        .filters-additional {
            display: inline-block;
            position: absolute;
            top: -40px;
            right: 15px;
        }
    }
    .timetable-columns {
        position: relative;
        &.show-timetable-column {
            padding-top: 16px;
        }
        .btn-timetable {
            position: absolute;
            left: -8px;
            font-size: 12px;
            border: 1px solid #354860;
            cursor: pointer;
            z-index: 1;
            background-color: #fafafa;
            color: #354860;
            font-weight: 500;
            height: 32px;
            line-height: 32px;
        }
        .show-timetable {
            top: -15px;
            right: -9px;
        }
        .hide-timetable {
            top: -15px;
            right: -9px;
        }
    }

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead.ant-table-thead {
        tr:first-child {
            th {
                &:nth-child(1),
                &:nth-child(2) {
                    position: relative;
                    &:before {
                        content: '';
                        width: 100%;
                        position: absolute;
                        top: -20px;
                        left: 0;
                        right: 0;
                        z-index: 1;
                        background-color: #fff!important;
                        height: 20px;
                    }
                }
            }
        }
    }

    .promoter-link {
        display: inline-block;
        color: #364760;
        border-bottom: 1px dashed #364760;
        cursor: pointer;
        span {
            color: #364760!important;
        }
    }

    .timetable-day-column {
        padding: 0;

        .timetable-day-cell {
            cursor: pointer;
            width: 100%;
            max-width: 35px;
            padding: 19px 0px;
            color: @primary;
            font-size: 14px;
            white-space: nowrap;

            &.filled {
                text-align: center;
            }

            &.has-comment {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    border: 10px solid transparent;
                    border-top: 10px solid rgba(0, 0, 0, .3);
                    border-right: 10px solid rgba(0, 0, 0, .3);
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    th.weekend-column {
        background-color: #EEEEEE !important;

        .day-of-week {
            color: #D47774;
        }
    }

    td.td-shop {
        min-width: 340px;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
            svg {
                path {
                    fill: #2A84D5;
                }
            }
        }

        .td-shop-additional {
            position: absolute;
            font-weight: bold;
            right: 10px;
            bottom: 5px;
        }
    }

    .td-diff, .td-over {
        font-size: 12px;
        position: absolute;
        right: 10px;
        bottom: 0;
    }
    .td-diff {
        color: @red;
    }
    .td-over {
        color: @green;
    }

    .day-of-week {
        font-size: 12px;
        position: relative;
        top: 4px;
        font-weight: normal;
    }

    .day-of-num {
        margin-top: 12px;
        position: relative;
        top: 7px;
    }

    .ant-table-expanded-row {
        & > td {
            padding: 0 !important;
            border-bottom: 0;
        }
        .ant-table {
            border: 0;
        }
        table {
            border: 0 !important;
        }
        .ant-table-container {
            border-left: 0;
        }
        tr.ant-table-row td.ant-table-cell:last-child {
            border-right: 0;
        }
    }

    table {
        border-top: 0;
        .ant-table-content {
            padding: 0;
        }
    }
    .ant-table-content {
        padding-top: 20px;
    }
    .ant-table {
        border-top: 0;
    }
    .ant-input-number {
        width: 100%;
        border-radius: 3px;
        min-height: 28px;
        margin: -5px 0;
        input {
            text-align: center;
            height: 28px;
        }
    }

    .ant-table-footer {
        .ant-badge {
            margin-right: 20px;
        }
    }
}

.timetable-day-bullet {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 2px;
}

.shop-plans-timetable-day-bullet {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 2px;
}

@media @mobile {
    .td-bold {
        font-weight: bold;
    }
    .td-other {
        font-size: 13px;
        opacity: 0.9;
    }
    .table-visits .ant-table-wrapper {
        thead {
            display: none;
        }
        tr {
            display: flex;
            flex-direction: column;
        }
    }
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.focus-category-color {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.mw100 {
    min-width: 100px;
}

.row-table {
    border-top: 1px solid #E9E9E9;
    .ant-col {
        padding: 8px;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
        strong {
            display: block;
        }
        span {
            color: #828282;
        }
    }
}
