.card {
    position: relative;

    .card-content {
        padding: 20px;
    }

    .card-title {
        height: 48px;
        line-height: 48px;
        padding: 0 15px;
        border-bottom: 1px solid #e9e9e9;

        .anticon {
            margin-right: 8px;
        }

        h3 {
            display: inline-block;
        }

        button.pull-right {
            margin-top: 9px;
        }
    }

    .card-body {
        padding: 15px;

        .ant-table {
            border-top: none;
        }
    }

    .card-body-no-padding {
        &, .table-no-pagination {
            padding: 0;
        }
    }

    .card-body-bordered {
        border-bottom: 1px solid #e9e9e9;
    }

    .ul-icon-block {
        li {
            padding-bottom: 5px;
        }
    }
}
