.guest-layout {
    width: 400px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 35px 40px;

    .logo-guest{
        margin: 20px 0 24px 2px;
        display: inline-block;
        height: 40px;
        width: 200px;
        path {
            fill: #ff4136;
        }
    }

    @media @mobile {
        width: 90%;
        max-width: 360px;
        padding: 16px;
        border-radius: 8px;
        background: @main-color;

        .logo-guest {
            width: 132px;
            height: 25px;
            path {
                fill: #fff;
            }
        }

        a {
            color: #fff;
            text-decoration: underline;
        }

        .guest-header {
            margin-bottom: 20px;
            h2 {
                font-size: 20px;
                line-height: 24px;
                color: #fff;
            }
        }
    }
}

.guest-layout, .guest-content:before {
    vertical-align: middle;
    display: inline-block;
}

.guest-content {
    text-align: center;
    display: block;
    background: #ececec;

    &:before {
        content: '';
        height: 100vh;
        width: 1px;
    }

    .copy{
        margin-top: -25px;
        position: absolute;
        text-align: center;
        width: 100%;
        color: #8e8e8e;
    }
}

.guest-header {
    margin-bottom: 50px;
    text-align: center;

    h2 {
        font-size: 16px;
        color: #444c63;
        font-weight: 300;
        text-align: center;
    }
}
