.table-task-row(@color) {
  & > td:first-child {
    border-left: 5px solid @color;

    @media @mobile {
      border-left-width: 0px;
    }
  }

  @media @mobile {
    border-left: 5px solid @color;
    padding-left: 10px;
  }
}

.tasks {
  .ant-table-tbody .ant-table-row {
    &.high {
      .table-task-row(#d47774);
    }
    &.medium {
      .table-task-row(#f3bc65);
    }
    &.low {
      .table-task-row(#9fbd78);
    }
  }
}

.task-text-block {
  padding: 15px;
}

.task-files-block {
  padding: 15px;
  padding-top: 0px;
}

.task-form {
  padding: 15px;
}
