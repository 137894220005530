@media @mobile {
  &.table-mobile.table-visits {
    padding: 0;
    .ant-table {
      background: transparent;
      color: #fff;
      colgroup {
        display: none;
      }
      tr.ant-table-row {
        display: grid;
        gap: 3px;
        background: @main-color;
        border-radius: 8px;
        margin-bottom: 8px;
        padding: 8px 12px;
        &:before, &:after {
          content: none;
        }
        a {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #fff;
        }
        &:hover > td {
          background: transparent;
        }
        .span-link {
          color: #8F8F8F;
        }
      }
      td {
        display: inline-flex;
      }
      .anticon-clock-circle,.no-geo-icon > span:last-child {
        display: none;
      }
      .td-date {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        grid-row-start: 2;
        grid-column-start: 1;
        grid-row-end: 4;
        grid-column-end: 2;
        align-items: center;
        .date-element {
          display: inline-grid;
          align-items: center;
          min-height: 100%;
          .anticon-calendar {
            display: none;
          }
        }
      }
      .td-used {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        grid-row-start: 2;
        grid-column-start: 2;
        grid-row-end: 4;
        grid-column-end: 3;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px!important;
      }
      .td-start {
        grid-row-start: 2;
        grid-column-start: 3;
        grid-row-end: 3;
        grid-column-end: 4;
        font-size: 12px;
        line-height: 14px;
        color: #8F8F8F;
      }
      .td-finish {
        font-size: 12px;
        line-height: 14px;
        color: #8F8F8F;
        grid-row-start: 3;
        grid-column-start: 3;
        grid-row-end: 4;
        grid-column-end: 4;
      }
      .td-shop {
        padding-top: 8px!important;
        font-size: 14px;
        line-height: 16px;
        border-top: 1px solid #3D4D65;
        grid-row-start: 4;
        grid-column-start: 1;
        grid-row-end: 5;
        grid-column-end: 4;
      }
      .td-address {
        font-size: 12px;
        line-height: 14px;
        color: #8F8F8F;
        grid-row-start: 5;
        grid-column-start: 1;
        grid-row-end: 6;
        grid-column-end: 4;
      }
      .td-status {
        grid-row-start: 6;
        grid-column-start: 1;
        grid-row-end: 7;
        grid-column-end: 4;
      }
    }
    .td-finish, .td-start {
      .anticon {
        color: #34C759;
      }
      .no-geo-icon {
        &:after {
          width: 15px;
        }
        .anticon {
          font-size: 12px;
          color: #FF3B30;
        }
      }
    }
  }
}
