.geo-icon{
  padding: 8px;
}
.no-geo-icon{
  position: relative;
  padding: 8px;
  &:after{
    content: '';
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 1px;
    background-color: @red;
    transform: rotate(50deg);
    top: 14px;
    left: 7px;
  }
  .anticon {
    color: @red;
    font-size: 16px;
  }
  & + .anticon-environment {
    display: none;
  }
}

.no-shop-icon{
  position: relative;
  &:after{
    content: '';
    display: inline-block;
    position: absolute;
    width: 22px;
    height: 1px;
    background-color: @red;
    transform: rotate(50deg);
    top: 9px;
    left: -3px;
  }
  .anticon-shop{
    color: @red;
    font-size: 14px;
    margin-right: 4px;
  }
}
