#root .left-menu {
  box-shadow: 0 2px 5px rgba(0,0,0,.26);
  background: #304056;
  z-index: 3;
  @media @mobile {
    top: 56px;
    width: 100%!important;
    min-width: 100%!important;
    max-width: 100%!important;
  }

  .ant-layout-sider {
    background: #304056;
  }

  &-wrapper .backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
  }

  @media @mobile {
    &.ant-layout-sider-collapsed {
      width: 0!important;
      min-width: 0!important;
      max-width: 0!important;
      flex: 0 0!important;
    }
  }

  .ant-menu{
    background: #304056;
    @media @desktop {
      margin-bottom: 48px;
    }
  }
  .ant-layout-sider-children {
    height: auto;
    @media @mobile {
      top: 56px;
      width: 100%;
      .logo {
        display: none;
      }
    }
  }
  .ant-menu-inline,.ant-menu-vertical{
    border: 0;
  }
  .ant-menu-inline .ant-menu-item{
    left: 0;
  }
  .ant-menu-item{
    background: #2d3b50;
    border-bottom: 1px solid #304056;
    &:hover{
      background: #293649;
    }
    &:after{
      display: none;
    }
    & > a{
      color: #fff;
    }
    .anticon{
      color: #6382a9;
      font-size: 16px;
    }
  }
  .ant-avatar-lg {
    font-size: 18px;
    border-radius: 50%;
    border: 3px solid;
    box-sizing: content-box;
  }
  .ant-layout-sider-trigger{
    transition: all .2s;
    color: #ffffff;
    background: #293649;
    @media @mobile {
      display: none;
    }
  }
  .avatar-block{
    display: inline-block;
  }
  .name-block{
    display: inline-block;
    color: #fff;
    margin-top: 5px;
  }
}
.ant-dropdown-menu-inline-collapsed-tooltip{
  display: none;
}
.ant-menu-inline-collapsed{
  width: 100%;
}
.ant-dropdown-menu-item{
  i{
    margin-right: 8px;
  }
}
.dropdown-custom-link {
  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    padding: 0;
    a, .span-link {
      display: block;
      padding: 4px 16px;
    }
  }
}
#root .left-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item.ant-menu-item-selected {
  background: #293649;
}
#root .ant-layout-sider-collapsed{
  .name-block{
    display: none;
  }
  .logo{
    background-position-x: -105px;
    margin-left: 30px;
  }
}
.profile-block{
  text-align: center;
  color: #fff;
  padding: 15px 0;
}
.profile-controls{
  padding: 20px 10px;
  .anticon{
    font-size: 20px;
    padding: 0 10px;
  }
}

.ant-menu-inline-collapsed-tooltip {
  .ant-tooltip-inner {
    a {
      color: #fff;
      .anticon {
        margin-right: 8px;
      }
    }
  }
}

.mobile-left-menu-toggle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #304056;
  padding: 20px 10px;
  font-size: 16px;
  display: none;
  z-index: 2;

  .anticon {
    float: right;
    color: #fff;
    margin-top: 4px;
  }
  a {
    color: #fff;
  }
  .logo {
    display: none;
  }
  @media @mobile {
    display: inline-block;
    padding: 15px 10px;
    z-index: 1020;
    .logo-wrap {
      display: flex;
      justify-content: center;
    }
    .logo {
      display: inline-block;
      margin: 0;
    }
  }
}
@media @mobile {
  .ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right{
    display: none!important;
  }
}
