@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4kaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4kaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4kaVc.ttf) format('truetype');
}
.card {
  position: relative;
}
.card .card-content {
  padding: 20px;
}
.card .card-title {
  height: 48px;
  line-height: 48px;
  padding: 0 15px;
  border-bottom: 1px solid #e9e9e9;
}
.card .card-title .anticon {
  margin-right: 8px;
}
.card .card-title h3 {
  display: inline-block;
}
.card .card-title button.pull-right {
  margin-top: 9px;
}
.card .card-body {
  padding: 15px;
}
.card .card-body .ant-table {
  border-top: none;
}
.card .card-body-no-padding,
.card .card-body-no-padding .table-no-pagination {
  padding: 0;
}
.card .card-body-bordered {
  border-bottom: 1px solid #e9e9e9;
}
.card .ul-icon-block li {
  padding-bottom: 5px;
}
.date-element {
  white-space: nowrap;
}
.product-element .anticon,
.address-element .anticon,
.price-element .anticon,
.shop-element .anticon {
  margin-right: 4px;
}
@media screen and (min-width: 768px) {
  .product-element .anticon,
  .address-element .anticon,
  .price-element .anticon,
  .shop-element .anticon {
    display: none;
  }
}
.news-text {
  padding: 15px;
}
@media screen and (min-width: 768px) {
  .news-date {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .table-mobile.table-news {
    padding: 0;
  }
  .table-mobile.table-news .ant-table {
    background: transparent;
  }
  .table-mobile.table-news .ant-table colgroup {
    display: none;
  }
  .table-mobile.table-news .ant-table tr.ant-table-row {
    background: #324153;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 8px 12px;
  }
  .table-mobile.table-news .ant-table tr.ant-table-row a {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
  }
  .table-mobile.table-news .ant-table tr.ant-table-row:hover > td {
    background: transparent;
  }
  .table-mobile.table-news .ant-table tr.ant-table-row .ql-viewer {
    margin-top: 8px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
  }
  .table-mobile.table-news .ant-table tr.ant-table-row .news-date {
    font-size: 12px;
    line-height: 14px;
    color: #8F8F8F;
  }
}
.documents .ant-avatar .anticon {
  padding-left: 2px;
}
.documents .ant-table-body td:first-child {
  text-align: center;
  width: 77px;
}
.documents .anticon-delete {
  cursor: pointer;
}
.table-file .ant-table-thead tr th:first-child {
  width: 60px;
}
@media screen and (max-width: 767px) {
  .table-file.table-mobile {
    padding: 0;
  }
  .table-file thead {
    display: none;
  }
  .table-file .ant-table {
    background: transparent;
  }
  .table-file .ant-table colgroup {
    display: none;
  }
  .table-file .ant-table tr.ant-table-row {
    background: #324153;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 8px 12px;
  }
  .table-file .ant-table tr.ant-table-row a {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
  }
  .table-file .ant-table tr.ant-table-row:hover > td {
    background: transparent;
  }
}
.table-file .ant-avatar {
  display: inline-flex;
  align-items: center;
  background: transparent;
  border-radius: 0;
}
.table-file .ant-avatar svg {
  width: 24px;
  height: 24px;
}
.tasks .ant-table-tbody .ant-table-row.high > td:first-child {
  border-left: 5px solid #d47774;
}
@media screen and (max-width: 767px) {
  .tasks .ant-table-tbody .ant-table-row.high > td:first-child {
    border-left-width: 0px;
  }
}
@media screen and (max-width: 767px) {
  .tasks .ant-table-tbody .ant-table-row.high {
    border-left: 5px solid #d47774;
    padding-left: 10px;
  }
}
.tasks .ant-table-tbody .ant-table-row.medium > td:first-child {
  border-left: 5px solid #f3bc65;
}
@media screen and (max-width: 767px) {
  .tasks .ant-table-tbody .ant-table-row.medium > td:first-child {
    border-left-width: 0px;
  }
}
@media screen and (max-width: 767px) {
  .tasks .ant-table-tbody .ant-table-row.medium {
    border-left: 5px solid #f3bc65;
    padding-left: 10px;
  }
}
.tasks .ant-table-tbody .ant-table-row.low > td:first-child {
  border-left: 5px solid #9fbd78;
}
@media screen and (max-width: 767px) {
  .tasks .ant-table-tbody .ant-table-row.low > td:first-child {
    border-left-width: 0px;
  }
}
@media screen and (max-width: 767px) {
  .tasks .ant-table-tbody .ant-table-row.low {
    border-left: 5px solid #9fbd78;
    padding-left: 10px;
  }
}
.task-text-block {
  padding: 15px;
}
.task-files-block {
  padding: 15px;
  padding-top: 0px;
}
.task-form {
  padding: 15px;
}
.detail-card .head-bar {
  display: table;
  width: 100%;
  margin: 0;
  color: #5a5a5a;
  background: #fafafa;
  padding: 15px;
  border-bottom: 1px solid #e6e6e6;
  line-height: 100%;
  font-weight: 400;
  font-size: 1.15rem;
}
.detail-card .head-bar .back-block,
.detail-card .head-bar .avatar-block,
.detail-card .head-bar .title-block,
.detail-card .head-bar .edit-block {
  display: table-cell;
  vertical-align: middle;
}
.detail-card .head-bar .avatar-block {
  width: 60px;
  line-height: 0;
}
.detail-card .head-bar .title-inscription {
  font-size: 11px;
  display: inline-block;
  line-height: 20px;
}
.detail-card .head-bar .back-block {
  width: 40px;
}
.detail-card .head-bar .title-text {
  font-size: 1.15rem;
  line-height: 100%;
  font-weight: 400;
  display: inline-block;
  padding: 4px 0;
}
.detail-card .head-bar .edit-block {
  text-align: right;
}
.detail-card .head-bar .edit-block button {
  margin-left: 10px;
}
.detail-card .info-head {
  background: #f7f7f7;
  padding: 0 15px;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #e9e9e9;
}
.detail-card .info-head .anticon {
  margin-right: 8px;
}
.detail-card .info-body {
  padding: 15px;
}
.detail-card .info-body .ant-row {
  margin-bottom: 10px;
}
.detail-card .head-spinner {
  height: 79px;
  padding-top: 30px;
}
.detail-card .head-spinner .spinner {
  padding: 0;
}
.user-photo-upload .ant-upload-drag-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.user-photo-upload .ant-upload-drag-container .user-photo-image {
  width: 120px;
  height: 120px;
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 50%;
}
.user-photo-upload .ant-upload-drag-container .anticon-plus {
  margin-top: 0px;
}
.user-photo-upload .ant-upload-disabled {
  cursor: default;
}
.user-photo-uploaded-container {
  position: relative;
  height: 120px;
  width: 120px;
}
.user-photo-uploaded-container .ant-upload {
  border-radius: 50%;
}
.user-photo-uploaded-container .ant-upload,
.user-photo-uploaded-container .ant-upload-drag .ant-upload.ant-upload-btn {
  padding: 0;
}
.user-photo-uploaded-container .hover-black {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: all 0.2s;
  border-radius: 50%;
}
.user-photo-uploaded-container:hover .hover-black {
  opacity: 0.5;
}
.user-photo-uploaded-container .user-photo-uploaded-controls {
  position: absolute;
  left: 0;
  height: 20px;
  width: 100%;
  opacity: 0;
  top: 50px;
  text-align: center;
  transition: all 0.2s;
}
.user-photo-uploaded-container .user-photo-uploaded-controls a,
.user-photo-uploaded-container .user-photo-uploaded-controls .span-link {
  margin: 5px;
}
.user-photo-uploaded-container .user-photo-uploaded-controls a .anticon,
.user-photo-uploaded-container .user-photo-uploaded-controls .span-link .anticon {
  color: #fff;
  font-size: 20px;
}
.user-photo-uploaded-container:hover .user-photo-uploaded-controls {
  opacity: 1;
}
.person-photo {
  background-position: center;
  background-size: cover;
}
.bind-table .ant-btn[disabled] {
  background-color: #9fbd78;
  border-color: #9fbd78;
  color: #fff;
}
.ql-editor {
  height: 160px;
}
.ql-viewer.ql-editor {
  height: auto;
  padding: 0;
}
.ql-viewer.ql-editor > * {
  cursor: inherit;
}
.ql-viewer.ql-editor img {
  max-width: 100%;
}
.ql-viewer.ql-editor a {
  text-decoration: underline;
}
.sales-form {
  padding: 15px;
}
@media screen and (max-width: 767px) {
  .sales-form .arrow-back {
    position: fixed;
  }
}
.product-list {
  padding-bottom: 46px;
  position: relative;
}
.product-list .ant-row {
  margin: 0 16px;
  padding: 8px 0;
  border-bottom: 1px solid #F0F0F0;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .product-list .ant-row {
    margin: 0;
  }
}
.product-list-pagination {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 6px;
}
.sales-form-product-list > .ant-row {
  padding: 8px 0;
  border-bottom: 1px solid #F0F0F0;
}
.sales-form-product-list > .ant-row .ant-form-item {
  display: inline-block;
  margin-bottom: 0;
}
.sales-form-product-list > .ant-row .product-remove {
  margin-right: -16px;
  color: #f54d2e;
}
.sales-form-product-list > .ant-row .ant-typography {
  padding: 0 6px;
  line-height: 32px;
}
.sales-form-product-list .sales-form-product-list-total {
  padding: 16px 0;
}
@media screen and (max-width: 767px) {
  .table-mobile.table-visits {
    padding: 0;
  }
  .table-mobile.table-visits .ant-table {
    background: transparent;
    color: #fff;
  }
  .table-mobile.table-visits .ant-table colgroup {
    display: none;
  }
  .table-mobile.table-visits .ant-table tr.ant-table-row {
    display: grid;
    gap: 3px;
    background: #324153;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 8px 12px;
  }
  .table-mobile.table-visits .ant-table tr.ant-table-row:before,
  .table-mobile.table-visits .ant-table tr.ant-table-row:after {
    content: none;
  }
  .table-mobile.table-visits .ant-table tr.ant-table-row a {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
  }
  .table-mobile.table-visits .ant-table tr.ant-table-row:hover > td {
    background: transparent;
  }
  .table-mobile.table-visits .ant-table tr.ant-table-row .span-link {
    color: #8F8F8F;
  }
  .table-mobile.table-visits .ant-table td {
    display: inline-flex;
  }
  .table-mobile.table-visits .ant-table .anticon-clock-circle,
  .table-mobile.table-visits .ant-table .no-geo-icon > span:last-child {
    display: none;
  }
  .table-mobile.table-visits .ant-table .td-date {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-column-end: 2;
    align-items: center;
  }
  .table-mobile.table-visits .ant-table .td-date .date-element {
    display: inline-grid;
    align-items: center;
    min-height: 100%;
  }
  .table-mobile.table-visits .ant-table .td-date .date-element .anticon-calendar {
    display: none;
  }
  .table-mobile.table-visits .ant-table .td-used {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    grid-row-start: 2;
    grid-column-start: 2;
    grid-row-end: 4;
    grid-column-end: 3;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px!important;
  }
  .table-mobile.table-visits .ant-table .td-start {
    grid-row-start: 2;
    grid-column-start: 3;
    grid-row-end: 3;
    grid-column-end: 4;
    font-size: 12px;
    line-height: 14px;
    color: #8F8F8F;
  }
  .table-mobile.table-visits .ant-table .td-finish {
    font-size: 12px;
    line-height: 14px;
    color: #8F8F8F;
    grid-row-start: 3;
    grid-column-start: 3;
    grid-row-end: 4;
    grid-column-end: 4;
  }
  .table-mobile.table-visits .ant-table .td-shop {
    padding-top: 8px!important;
    font-size: 14px;
    line-height: 16px;
    border-top: 1px solid #3D4D65;
    grid-row-start: 4;
    grid-column-start: 1;
    grid-row-end: 5;
    grid-column-end: 4;
  }
  .table-mobile.table-visits .ant-table .td-address {
    font-size: 12px;
    line-height: 14px;
    color: #8F8F8F;
    grid-row-start: 5;
    grid-column-start: 1;
    grid-row-end: 6;
    grid-column-end: 4;
  }
  .table-mobile.table-visits .ant-table .td-status {
    grid-row-start: 6;
    grid-column-start: 1;
    grid-row-end: 7;
    grid-column-end: 4;
  }
  .table-mobile.table-visits .td-finish .anticon,
  .table-mobile.table-visits .td-start .anticon {
    color: #34C759;
  }
  .table-mobile.table-visits .td-finish .no-geo-icon:after,
  .table-mobile.table-visits .td-start .no-geo-icon:after {
    width: 15px;
  }
  .table-mobile.table-visits .td-finish .no-geo-icon .anticon,
  .table-mobile.table-visits .td-start .no-geo-icon .anticon {
    font-size: 12px;
    color: #FF3B30;
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}
.logo {
  display: inline-block;
  height: 26px;
  width: 125px;
  background-size: auto;
  margin: 20px 0 0 60px;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9ItCh0LvQvtC5XzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTgwIDM0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxODAgMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojRkZGRkZGO308L3N0eWxlPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNjgsMC42bC01LjYsNy45VjAuNmgtMTAuMXYzMi43aDEwLjF2LTIxaDkuMkwxODAsMC42SDE2OHogTTEyOS40LDI0LjZjLTQuMywwLTcuNC0zLjItNy40LTcuNmMwLTQuNCwzLjItNy43LDcuNC03LjdjNC4yLDAsNy40LDMuMyw3LjQsNy43QzEzNi45LDIxLjQsMTMzLjcsMjQuNiwxMjkuNCwyNC42eiBNMTI5LjQsMGMtOS44LDAtMTcuNSw3LjUtMTcuNSwxN2MwLDkuNSw3LjcsMTcsMTcuNSwxN2M5LjgsMCwxNy41LTcuNSwxNy41LTE3QzE0Nyw3LjUsMTM5LjMsMCwxMjkuNCwweiBNOTcuNywyNC42Yy00LjYsMC03LjgtMy4xLTcuOC03LjZjMC00LjQsMy4zLTcuNyw3LjYtNy43YzIuMiwwLDQuNCwwLjksNiwyLjVsNS41LTcuN0MxMDUuOSwxLjUsMTAxLjgsMCw5Ny41LDBjLTkuOSwwLTE3LjcsNy41LTE3LjcsMTdjMCw5LjcsNy42LDE3LDE3LjcsMTdjNC44LDAsOC44LTEuNCwxMi4xLTQuM2wtNS41LTcuOEMxMDIuNiwyMy4zLDEwMC40LDI0LjYsOTcuNywyNC42TDk3LjcsMjQuNnogTTYxLjUsMGMtMi45LDAtNy43LDEuNC05LjQsMy41VjAuNmgtOS44djMyLjdoMTAuMVYxMi42YzEuMS0xLjQsMy43LTMuMyw2LjgtMy4zYzEuNywwLDMuMiwwLjUsNC4xLDEuNWMwLjgsMC44LDEuNiwyLDEuNiw1LjJ2MTcuM0g3NXYtMjFDNzUsNC4xLDY5LDAsNjEuNSwwTDYxLjUsMHogTTE3LjUsMjQuNmMtNC4zLDAtNy40LTMuMi03LjQtNy42YzAtNC40LDMuMi03LjcsNy40LTcuN2M0LjIsMCw3LjQsMy4zLDcuNCw3LjdDMjQuOSwyMS40LDIxLjgsMjQuNiwxNy41LDI0LjZ6IE0yNC41LDMuNUMyMi40LDEsMTkuMywwLDE2LjIsMEM3LjEsMCwwLDcuNSwwLDE3YzAsNC4zLDEuNSw4LjMsNC4yLDExLjRjMywzLjUsNy43LDUuNSwxMi41LDUuNWMyLjksMCw2LjItMS4yLDcuOS0zLjR2Mi44aDkuOFYwLjZoLTkuOFYzLjVMMjQuNSwzLjV6Ii8+PC9zdmc+);
}
#root {
  height: 100%;
}
#root .left-menu {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  box-shadow: none;
}
@media screen and (min-width: 768px) {
  #root .left-menu {
    position: relative;
  }
}
#root .left-menu-wrapper {
  background: #304156;
}
#root .left-menu .ant-layout-sider-children {
  position: fixed;
  top: 0;
  bottom: 48px;
  width: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.2s;
}
#root .left-menu.ant-layout-sider-collapsed .ant-layout-sider-children {
  width: 71px;
}
@media screen and (max-width: 767px) {
  #root .left-menu.ant-layout-sider-collapsed .ant-layout-sider-children {
    width: 0;
  }
}
#root .layout-wrapper {
  min-height: 100vh;
}
#root > .spinner {
  margin: 0;
  position: relative;
  height: 100vh;
  line-height: 100vh;
}
#root > .spinner .ant-spin {
  display: inline-block;
  vertical-align: middle;
}
.spinner {
  padding: 10px 0;
}
.fab-btn {
  position: fixed !important;
  bottom: 40px;
  right: 40px;
  width: 60px !important;
  height: 60px !important;
  z-index: 2;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 767px) {
  .fab-btn {
    bottom: 10px;
    right: 20px;
  }
}
.task-form label:not(.ant-radio-button-wrapper) {
  display: block;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 8px;
}
.task-table-text {
  overflow: hidden;
  max-height: 35px;
  display: inline-block;
  margin-bottom: -5px;
}
.task-table-text h1,
.task-table-text h2,
.task-table-text h3,
.task-table-text h4,
.task-table-text h5,
.task-table-text h6,
.task-table-text p,
.task-table-text strong,
.task-table-text em,
.task-table-text u {
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  color: #108ee9;
}
.task-text-block ul,
.task-text-block ol {
  padding-left: 40px;
}
.task-text-block ul {
  list-style-type: disc;
}
.task-text-block ol {
  list-style-type: decimal;
}
.text-center {
  text-align: center;
}
.text-bold {
  font-weight: bold;
}
.ant-row [class^=ant-col-] {
  margin-bottom: 5px;
}
body a {
  color: #2A84D5;
}
td.timetable-day-column {
  width: 35px;
  padding: 0 !important;
  text-align: center;
}
td.timetable-day-column > .span-link {
  padding: 13.5px 8px;
  display: inline-block;
  width: 100%;
  color: #fff;
  font-size: 10px;
}
.ant-table .ant-table-container .timetable-gold {
  background: #F6C242;
}
.ant-table .ant-table-container .timetable-orange {
  background: #EB3224;
}
.ant-table .ant-table-container .timetable-yellow {
  background: #FFFD54;
  color: #000;
}
.ant-table .ant-table-container .timetable-green {
  background: #A0CD63;
}
.ant-table .ant-table-container .timetable-blue {
  background: #2A84D5;
}
.ant-table .ant-table-container .timetable-geekblue {
  background: #4BAEEB;
}
.ant-table .ant-table-container .timetable-cyan {
  background: #ecccb2;
}
.ant-table .ant-table-container .timetable-red {
  background: #f54d2e;
}
.ant-table .ant-table-container .timetable-dred {
  background: #D5722D;
}
.ant-table .ant-table-container .timetable-black {
  background: #5C2825;
  color: #fff;
}
.ant-table .ant-table-container .timetable-beige {
  background: #BFC0BF;
}
.ant-table .ant-table-container .timetable-purple {
  background: #68389A;
}
.ant-table .ant-table-container .timetable-grey {
  background: #BFC0BF;
}
.ant-table .ant-table-header .th-timetable-gold {
  background: #F6C242;
}
.ant-table .ant-table-header .th-timetable-orange {
  background: #EB3224;
  color: #fff;
}
.ant-table .ant-table-header .th-timetable-yellow {
  background: #FFFD54;
  color: #000;
}
.ant-table .ant-table-header .th-timetable-green {
  background: #A0CD63;
}
.ant-table .ant-table-header .th-timetable-blue {
  background: #2A84D5;
}
.ant-table .ant-table-header .th-timetable-geekblue {
  background: #4BAEEB;
}
.ant-table .ant-table-header .th-timetable-cyan {
  background: #ecccb2;
}
.ant-table .ant-table-header .th-timetable-red {
  background: #f54d2e;
  color: #fff;
}
.ant-table .ant-table-header .th-timetable-dred {
  background: #D5722D;
  color: #fff;
}
.ant-table .ant-table-header .th-timetable-black {
  background: #5C2825;
  color: #fff;
}
.ant-table .ant-table-header .th-timetable-beige {
  background: #BFC0BF;
}
.ant-table .ant-table-header .th-timetable-purple {
  background: #68389A;
}
.ant-table .ant-table-header .th-timetable-grey {
  background: #BFC0BF;
}
.ant-badge-status-orange {
  background: #EB3224;
}
.ant-badge-status-gold {
  background: #F6C242;
}
.ant-badge-status-yellow {
  background: #FFFD54;
  color: #000;
}
.ant-badge-status-green {
  background: #A0CD63;
}
.ant-badge-status-blue {
  background: #2A84D5;
}
.ant-badge-status-geekblue {
  background: #4BAEEB;
}
.ant-badge-status-cyan {
  background: #ecccb2;
}
.ant-badge-status-red {
  background: #f54d2e;
}
.ant-badge-status-dred {
  background: #D5722D;
  color: #fff;
}
.ant-badge-status-black {
  background: #5C2825;
  color: #fff;
}
.ant-badge-status-beige {
  background: #BFC0BF;
}
.ant-badge-status-purple {
  background: #68389A;
}
.ant-badge-status-grey {
  background: #BFC0BF;
}
.w-b-normal {
  word-break: normal;
}
.timetable .ant-table-tbody {
  line-height: 1;
}
.timetable .ant-table-thead > tr > th {
  padding: 16px 8px;
}
.timetable .ant-table-tbody > tr > td {
  padding: 8px;
}
.timetable .ant-table-thead > tr > th:first-child,
.timetable .ant-table-tbody > tr > td:first-child {
  padding-left: 15px;
  min-width: 150px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ql-tooltip {
  top: 0 !important;
  left: 0 !important;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.text-right {
  text-align: right;
}
.pull-clear {
  clear: both;
}
.bg-color-success {
  background-color: #A0CD63;
}
.bg-color-info {
  background-color: #2A84D5;
}
.bg-color-warning {
  background-color: #EB3224;
}
.bg-color-primary {
  background-color: #2B3D4F;
}
.p15 {
  padding: 15px;
}
.pt0 {
  padding-top: 0;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
.ml5 {
  margin-left: 5px;
}
.mr15 {
  margin-right: 15px;
}
.ml10 {
  margin-left: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.bold {
  font-weight: 600;
}
.color-red {
  color: #f04134;
}
.hide {
  display: none !important;
}
@media screen and (max-width: 767px) {
  .hide-mobile {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .hide-desktop {
    display: none !important;
  }
}
.font-small {
  font-size: 0.9em;
}
.mw250 {
  max-width: 250px;
}
.news-item,
.news-full-header-text {
  word-break: break-word;
}
.layout-wrap {
  background: #EFF2F5;
}
@media screen and (max-width: 767px) {
  .layout-wrap {
    padding: 16px 16px 24px;
    min-height: 100%;
  }
}
@media screen and (max-width: 360px) {
  .layout-wrap {
    padding: 16px 8px 24px;
  }
}
.sale-item {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #3D4D65;
  padding: 8px 0;
  position: relative;
  cursor: pointer;
}
.sale-item .price {
  color: #9FBD78;
  white-space: nowrap;
}
.sale-item .sum {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
}
.sale-item .arrow-right {
  position: absolute;
  right: -13px;
  margin-top: -8px;
  width: 12px;
  height: 12px;
  top: 50%;
}
.price-item {
  text-align: right;
  padding-left: 8px;
}
.sale-list {
  background: #324153;
  padding: 8px 16px 16px;
  color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
}
.sale-list .sale-item:first-child {
  border: 0;
}
.sale-list .shop-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8F8F8F;
  margin-top: 8px;
  font-size: 12px;
  line-height: 14px;
}
.sale-list .shop-name .ant-btn {
  color: #8F8F8F;
}
.line-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 8px;
}
.line-date .title-item {
  margin: 0;
}
.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #324153;
  margin-bottom: 16px;
}
.title-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #324153;
  margin: 20px 0 6px;
}
.title-item-sub {
  margin-top: -2px;
  font-size: 14px;
  line-height: 16px;
  color: #595959;
  font-weight: normal;
}
.total-sum {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #9FBD78;
}
.title-count {
  margin-right: 8px;
  font-size: 16px;
  line-height: 19px;
  color: #324153;
  font-weight: 500;
}
.arrow-back {
  display: none;
}
.plan-head {
  display: flex;
  margin-bottom: 0;
  font-size: 20px;
}
@media screen and (max-width: 360px) {
  .plan-head {
    font-size: 18px;
  }
}
.plan-head .plan-title {
  white-space: nowrap;
}
.text-nowrap {
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  html {
    background: #e8ebee;
  }
  .mt15 .ant-spin-spinning {
    width: 100%;
  }
  .ant-spin-spinning {
    position: absolute;
    top: 50%;
  }
  .arrow-back {
    display: inline-block;
    position: fixed;
    top: 8px;
    left: 0;
    z-index: 1020;
    border: 0;
  }
  .arrow-back svg {
    width: 24px;
  }
}
.guest-layout {
  width: 400px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 35px 40px;
}
.guest-layout .logo-guest {
  margin: 20px 0 24px 2px;
  display: inline-block;
  height: 40px;
  width: 200px;
}
.guest-layout .logo-guest path {
  fill: #ff4136;
}
@media screen and (max-width: 767px) {
  .guest-layout {
    width: 90%;
    max-width: 360px;
    padding: 16px;
    border-radius: 8px;
    background: #324153;
  }
  .guest-layout .logo-guest {
    width: 132px;
    height: 25px;
  }
  .guest-layout .logo-guest path {
    fill: #fff;
  }
  .guest-layout a {
    color: #fff;
    text-decoration: underline;
  }
  .guest-layout .guest-header {
    margin-bottom: 20px;
  }
  .guest-layout .guest-header h2 {
    font-size: 20px;
    line-height: 24px;
    color: #fff;
  }
}
.guest-layout,
.guest-content:before {
  vertical-align: middle;
  display: inline-block;
}
.guest-content {
  text-align: center;
  display: block;
  background: #ececec;
}
.guest-content:before {
  content: '';
  height: 100vh;
  width: 1px;
}
.guest-content .copy {
  margin-top: -25px;
  position: absolute;
  text-align: center;
  width: 100%;
  color: #8e8e8e;
}
.guest-header {
  margin-bottom: 50px;
  text-align: center;
}
.guest-header h2 {
  font-size: 16px;
  color: #444c63;
  font-weight: 300;
  text-align: center;
}
.layout-wrapper {
  padding: 24px;
}
@media screen and (max-width: 767px) {
  .layout-wrapper {
    padding: 56px 0 0;
  }
}
.content-wrapper {
  background: #fff;
}
@media screen and (max-width: 767px) {
  .content-wrapper {
    min-height: 100%;
  }
}
#root .left-menu {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  background: #304056;
  z-index: 3;
}
@media screen and (max-width: 767px) {
  #root .left-menu {
    top: 56px;
    width: 100%!important;
    min-width: 100%!important;
    max-width: 100%!important;
  }
}
#root .left-menu .ant-layout-sider {
  background: #304056;
}
#root .left-menu-wrapper .backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  #root .left-menu.ant-layout-sider-collapsed {
    width: 0!important;
    min-width: 0!important;
    max-width: 0!important;
    flex: 0 0!important;
  }
}
#root .left-menu .ant-menu {
  background: #304056;
}
@media screen and (min-width: 768px) {
  #root .left-menu .ant-menu {
    margin-bottom: 48px;
  }
}
#root .left-menu .ant-layout-sider-children {
  height: auto;
}
@media screen and (max-width: 767px) {
  #root .left-menu .ant-layout-sider-children {
    top: 56px;
    width: 100%;
  }
  #root .left-menu .ant-layout-sider-children .logo {
    display: none;
  }
}
#root .left-menu .ant-menu-inline,
#root .left-menu .ant-menu-vertical {
  border: 0;
}
#root .left-menu .ant-menu-inline .ant-menu-item {
  left: 0;
}
#root .left-menu .ant-menu-item {
  background: #2d3b50;
  border-bottom: 1px solid #304056;
}
#root .left-menu .ant-menu-item:hover {
  background: #293649;
}
#root .left-menu .ant-menu-item:after {
  display: none;
}
#root .left-menu .ant-menu-item > a {
  color: #fff;
}
#root .left-menu .ant-menu-item .anticon {
  color: #6382a9;
  font-size: 16px;
}
#root .left-menu .ant-avatar-lg {
  font-size: 18px;
  border-radius: 50%;
  border: 3px solid;
  box-sizing: content-box;
}
#root .left-menu .ant-layout-sider-trigger {
  transition: all 0.2s;
  color: #ffffff;
  background: #293649;
}
@media screen and (max-width: 767px) {
  #root .left-menu .ant-layout-sider-trigger {
    display: none;
  }
}
#root .left-menu .avatar-block {
  display: inline-block;
}
#root .left-menu .name-block {
  display: inline-block;
  color: #fff;
  margin-top: 5px;
}
.ant-dropdown-menu-inline-collapsed-tooltip {
  display: none;
}
.ant-menu-inline-collapsed {
  width: 100%;
}
.ant-dropdown-menu-item i {
  margin-right: 8px;
}
.dropdown-custom-link .ant-dropdown-menu-item,
.dropdown-custom-link .ant-dropdown-menu-submenu-title {
  padding: 0;
}
.dropdown-custom-link .ant-dropdown-menu-item a,
.dropdown-custom-link .ant-dropdown-menu-submenu-title a,
.dropdown-custom-link .ant-dropdown-menu-item .span-link,
.dropdown-custom-link .ant-dropdown-menu-submenu-title .span-link {
  display: block;
  padding: 4px 16px;
}
#root .left-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item.ant-menu-item-selected {
  background: #293649;
}
#root .ant-layout-sider-collapsed .name-block {
  display: none;
}
#root .ant-layout-sider-collapsed .logo {
  background-position-x: -105px;
  margin-left: 30px;
}
.profile-block {
  text-align: center;
  color: #fff;
  padding: 15px 0;
}
.profile-controls {
  padding: 20px 10px;
}
.profile-controls .anticon {
  font-size: 20px;
  padding: 0 10px;
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner a {
  color: #fff;
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner a .anticon {
  margin-right: 8px;
}
.mobile-left-menu-toggle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #304056;
  padding: 20px 10px;
  font-size: 16px;
  display: none;
  z-index: 2;
}
.mobile-left-menu-toggle .anticon {
  float: right;
  color: #fff;
  margin-top: 4px;
}
.mobile-left-menu-toggle a {
  color: #fff;
}
.mobile-left-menu-toggle .logo {
  display: none;
}
@media screen and (max-width: 767px) {
  .mobile-left-menu-toggle {
    display: inline-block;
    padding: 15px 10px;
    z-index: 1020;
  }
  .mobile-left-menu-toggle .logo-wrap {
    display: flex;
    justify-content: center;
  }
  .mobile-left-menu-toggle .logo {
    display: inline-block;
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  .ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
    display: none!important;
  }
}
.ant-alert.ant-alert-no-icon.text-center {
  padding: 8px;
}
.alert-text {
  display: inline-flex;
  align-items: center;
  color: #f54d2e;
  font-size: 14px;
}
.alert-text .anticon {
  margin-right: 8px;
}
.ant-btn.ant-btn-success {
  background-color: #34C759;
  border-color: #34C759;
  color: #fff;
}
.ant-btn.ant-btn-success:hover,
.ant-btn.ant-btn-success:focus {
  background-color: #9fc56a;
  border-color: #9fc56a;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .ant-btn {
    height: 38px;
  }
}
.action-icon {
  cursor: pointer;
  font-size: 16px;
  opacity: 0.6;
  transition: opacity 0.3s;
}
.action-icon:hover {
  opacity: 1;
}
.primary-dashed {
  color: #2A84D5;
  border-color: #2A84D5;
}
.ant-btn.photo-button {
  display: block;
  height: auto !important;
}
@media screen and (max-width: 767px) {
  .ant-btn.photo-button {
    font-size: 11px;
    padding: 4px 8px;
    width: 100px;
    min-height: 100px;
  }
}
.ant-modal-wrap {
  z-index: 1030;
}
.ant-modal-wrap .ant-select,
.ant-modal-wrap .ant-cascader-picker,
.ant-modal-wrap .ant-input-number {
  width: 100%;
}
.ant-input-search,
.ant-calendar-picker {
  width: 100%;
}
.ant-select.shop-select {
  width: 170px;
}
.ant-select.shop-select .ant-select-selection--single {
  height: 34px !important;
  padding-top: 3px;
}
.ant-select.shop-select .ant-select-selection-selected-value {
  display: none !important;
}
.ant-select.shop-select .ant-select-selection__placeholder {
  display: block !important;
  color: rgba(0, 0, 0, 0.65);
  overflow: visible;
  white-space: normal;
  width: 100%;
  text-align: center;
}
.ant-select.shop-select .ant-select-arrow {
  display: none;
}
.shop-select-option .shop-select-name {
  font-weight: 600;
}
.shop-select-option .shop-select-distance {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}
@media screen and (min-width: 768px) {
  .ant-select-dropdown .ant-select-dropdown-menu-item.shop-select-option {
    padding-right: 100px;
  }
}
.ant-select-dropdown-menu-item-selected.shop-select-option .shop-select-address {
  font-weight: 400 !important;
}
.ant-form-item {
  flex-direction: column;
}
.ant-form-item .ant-form-item-label {
  text-align: left;
}
.ant-form-item .ant-form-item-label > label::after {
  content: none;
}
.ant-form-item .ant-form-item-explain {
  min-height: auto;
}
.ant-form-item.form-item-inline {
  flex-direction: row;
}
.sync-row {
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
}
.sync-row .ant-col > b {
  text-transform: capitalize;
  line-height: 32px;
}
.ant-form-item-control {
  line-height: inherit;
}
.shop-select-option {
  overflow: hidden;
  text-overflow: ellipsis;
}
.span-link {
  color: #2A84D5;
  cursor: pointer;
}
.ant-form-item-control-input-content .ant-picker {
  width: 100%;
}
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.rdw-editor-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px;
}
@media screen and (max-width: 767px) {
  .ant-modal:not(.text-center) {
    top: 0!important;
    max-width: 100%;
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }
  .ant-modal:not(.text-center) .ant-modal-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0;
  }
  .ant-modal:not(.text-center) .ant-modal-content .ant-modal-header {
    padding: 16px;
  }
  .ant-modal:not(.text-center) .ant-modal-content .ant-modal-body {
    min-height: calc(100vh - 55px - 59px);
    padding: 16px;
  }
}
.filters-bar {
  clear: both;
  padding: 10px 15px;
}
.filters-bar .controls-block {
  text-align: right;
  padding-right: 10px;
}
@media screen and (max-width: 767px) {
  .filters-bar .controls-block {
    text-align: left;
  }
}
.filters-bar .ant-form-item {
  margin-bottom: 0 !important;
}
.filters-bar button {
  margin-right: 8px;
}
.filters-bar button:last-child {
  margin-right: 0;
}
.filters-bar .filters-additional {
  text-align: right;
  padding-bottom: 10px;
}
.filters-bar .filters-additional:after {
  content: '';
  display: block;
  clear: both;
}
.filters-bar .mobile-filter-button {
  text-align: right;
}
@media screen and (max-width: 767px) {
  .filters-bar .mobile-filter-button {
    float: right;
    margin-top: -3px;
    width: auto;
  }
}
.filters-bar .ant-collapse .ant-collapse-item {
  border-bottom: 0;
}
.filters-bar .ant-collapse .ant-collapse-item .ant-collapse-header {
  background-color: transparent !important;
  padding: 0 0 15px 25px;
}
.filters-bar .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  line-height: 22px;
  left: 5px;
}
.filters-bar .ant-collapse .ant-collapse-item .ant-collapse-content,
.filters-bar .ant-collapse .ant-collapse-item .ant-collapse-content-box {
  overflow: visible;
  border-top: 0;
  padding: 0;
  margin-top: 5px;
}
.filters-bar .filters-row .ant-col {
  margin-bottom: 4px;
}
@media screen and (max-width: 767px) {
  .filters-bar {
    padding: 0;
  }
  .range-sales {
    top: 121px!important;
  }
  .range-sales .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }
}
.ant-avatar.file-pdf {
  background-color: #f44336;
}
.ant-avatar.file-excel {
  background-color: #4caf50;
}
.ant-avatar.file-text {
  background-color: #2196f3;
}
.ant-avatar.file-ppt {
  background-color: #ff9800;
}
.ant-table {
  width: 100%;
  border-top: 1px solid #e9e9e9;
  color: rgba(0, 0, 0, 0.65);
}
.ant-table,
.ant-table table {
  border-radius: 0;
}
@media screen and (max-width: 767px) {
  .ant-table table {
    width: 100% !important;
  }
}
.ant-table .ant-table-tbody .table-row-odd .ant-table-cell {
  background-color: #fbfbfb;
}
.ant-table .ant-table-cell.border-left {
  border-left: 2px solid #f0f0f0 !important;
}
.ant-table .ant-table-cell.border-right {
  border-right: 1px solid #f0f0f0 !important;
}
.ant-table .ant-table-cell.cell-loading {
  background-color: #eee;
  color: #aaa;
}
.ant-table .ant-table-cell textarea.ant-input-sm {
  padding: 3px 7px;
}
.ant-table .ant-table-container table th.ant-table-cell:first-child,
.ant-table .ant-table-container table th.ant-table-cell:last-child {
  border-radius: 0;
}
.ant-table .td-text {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-table .error-message {
  color: #f54d2e;
}
.plan-table .ant-table-row-level-0 .ant-table-cell-with-append {
  color: #293649;
}
.plan-table .ant-table-row-level-1 .ant-table-cell-with-append div span {
  font-weight: 500;
  color: #333;
}
.plan-table .ant-badge-status-dot {
  border: 1px solid #2B3D4F;
  width: 16px;
  height: 16px;
}
.shop-plans-promoter-link {
  font-weight: 500;
}
.ant-table-thead > tr > th {
  white-space: nowrap;
  font-weight: normal;
  font-size: 13px;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  word-break: normal;
}
.th-multi .ant-table-thead > tr > th {
  padding: 5px 8px;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.th-multi .ant-table-thead > tr > th:last-child {
  border-right: 0;
}
.ant-table-wrapper:not(.row-table-selection) tr td:first-child,
.ant-table-wrapper:not(.row-table-selection) tr th:first-child {
  padding-left: 15px;
}
.ant-table-wrapper:not(.row-table-selection) tr td:last-child,
.ant-table-wrapper:not(.row-table-selection) tr th:last-child {
  padding-right: 15px;
}
.timetable .ant-table-wrapper:not(.row-table-selection) tr td:last-child,
.timetable .ant-table-wrapper:not(.row-table-selection) tr th:last-child {
  padding-right: inherit;
}
.ant-table-pagination.ant-pagination {
  margin-right: 16px;
}
.table-no-header .ant-table-thead th {
  display: none;
}
.formatted-price {
  white-space: nowrap;
}
.ant-table-wrapper .ant-spin .ant-spin-dot {
  top: 80px !important;
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td,
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e3e7ed;
}
.shop-plans-table .timetable-columns {
  position: relative;
}
.shop-plans-table .timetable-columns.show-timetable-column {
  padding-top: 16px;
}
.shop-plans-table .timetable-columns .btn-timetable {
  position: absolute;
  left: -8px;
  font-size: 12px;
  border: 1px solid #354860;
  cursor: pointer;
  z-index: 1;
  background-color: #fafafa;
  color: #354860;
  font-weight: 500;
  height: 20px;
}
.shop-plans-table .timetable-columns .show-timetable {
  top: -6px;
  right: -9px;
}
.shop-plans-table .timetable-columns .hide-timetable {
  top: -17px;
}
.shop-plans-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead.ant-table-thead tr:first-child th:nth-child(1),
.shop-plans-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead.ant-table-thead tr:first-child th:nth-child(2) {
  position: relative;
}
.shop-plans-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead.ant-table-thead tr:first-child th:nth-child(1):before,
.shop-plans-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead.ant-table-thead tr:first-child th:nth-child(2):before {
  content: '';
  width: 100%;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff !important;
  height: 20px;
}
.shop-plans-table .shop-plans-promoter-link {
  display: inline-block;
  color: #364760;
  border-bottom: 1px dashed #364760;
  cursor: pointer;
}
.shop-plans-table .shop-plans-promoter-link span {
  color: #364760 !important;
}
.shop-plans-table .shop-plans-timetable-day-column {
  padding: 0;
}
.shop-plans-table .shop-plans-timetable-day-column .shop-plans-timetable-day-cell {
  cursor: pointer;
  width: 100%;
  padding: 19px 12px;
  color: #fff;
  white-space: nowrap;
}
.shop-plans-table .shop-plans-timetable-day-column .shop-plans-timetable-day-cell.filled {
  padding: 16px 0;
  text-align: center;
}
.shop-plans-table .shop-plans-timetable-day-column .shop-plans-timetable-day-cell.has-comment {
  position: relative;
}
.shop-plans-table .shop-plans-timetable-day-column .shop-plans-timetable-day-cell.has-comment::after {
  content: '';
  position: absolute;
  border: 10px solid transparent;
  border-top: 10px solid rgba(0, 0, 0, 0.3);
  border-right: 10px solid rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
}
.shop-plans-table th.weekend-column {
  background-color: #EEEEEE !important;
}
.shop-plans-table th.weekend-column .day-of-week {
  color: #D47774;
}
.shop-plans-table .day-of-week {
  font-size: 12px;
  position: relative;
  top: 4px;
}
.shop-plans-table .day-of-num {
  margin-top: 12px;
  position: relative;
  top: 7px;
}
.shop-plans-table .ant-table-expanded-row > td {
  padding: 0 !important;
  border-bottom: 0;
}
.shop-plans-table .ant-table-expanded-row .ant-table {
  border: 0;
}
.shop-plans-table .ant-table-expanded-row table {
  border: 0 !important;
}
.shop-plans-table .ant-table-expanded-row .ant-table-container {
  border-left: 0;
}
.shop-plans-table .ant-table-expanded-row tr.ant-table-row td.ant-table-cell:last-child {
  border-right: 0;
}
.shop-plans-table table {
  border-top: 0;
}
.shop-plans-table table .ant-table-content {
  padding: 0;
}
.shop-plans-table .ant-table-content {
  padding-top: 20px;
}
.shop-plans-table .ant-table {
  border-top: 0;
}
.shop-plans-table .ant-input-number {
  width: 100%;
  border-radius: 3px;
  min-height: 28px;
  margin: -5px 0;
}
.shop-plans-table .ant-input-number input {
  text-align: center;
  height: 28px;
}
.time-report-table .filters-bar {
  position: relative;
}
.time-report-table .filters-bar .filters-additional {
  display: inline-block;
  position: absolute;
  top: -40px;
  right: 15px;
}
.time-report-table .timetable-columns {
  position: relative;
}
.time-report-table .timetable-columns.show-timetable-column {
  padding-top: 16px;
}
.time-report-table .timetable-columns .btn-timetable {
  position: absolute;
  left: -8px;
  font-size: 12px;
  border: 1px solid #354860;
  cursor: pointer;
  z-index: 1;
  background-color: #fafafa;
  color: #354860;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
}
.time-report-table .timetable-columns .show-timetable {
  top: -15px;
  right: -9px;
}
.time-report-table .timetable-columns .hide-timetable {
  top: -15px;
  right: -9px;
}
.time-report-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead.ant-table-thead tr:first-child th:nth-child(1),
.time-report-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead.ant-table-thead tr:first-child th:nth-child(2) {
  position: relative;
}
.time-report-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead.ant-table-thead tr:first-child th:nth-child(1):before,
.time-report-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead.ant-table-thead tr:first-child th:nth-child(2):before {
  content: '';
  width: 100%;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #fff !important;
  height: 20px;
}
.time-report-table .promoter-link {
  display: inline-block;
  color: #364760;
  border-bottom: 1px dashed #364760;
  cursor: pointer;
}
.time-report-table .promoter-link span {
  color: #364760 !important;
}
.time-report-table .timetable-day-column {
  padding: 0;
}
.time-report-table .timetable-day-column .timetable-day-cell {
  cursor: pointer;
  width: 100%;
  max-width: 35px;
  padding: 19px 0px;
  color: #2B3D4F;
  font-size: 14px;
  white-space: nowrap;
}
.time-report-table .timetable-day-column .timetable-day-cell.filled {
  text-align: center;
}
.time-report-table .timetable-day-column .timetable-day-cell.has-comment {
  position: relative;
}
.time-report-table .timetable-day-column .timetable-day-cell.has-comment::after {
  content: '';
  position: absolute;
  border: 10px solid transparent;
  border-top: 10px solid rgba(0, 0, 0, 0.3);
  border-right: 10px solid rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
}
.time-report-table th.weekend-column {
  background-color: #EEEEEE !important;
}
.time-report-table th.weekend-column .day-of-week {
  color: #D47774;
}
.time-report-table td.td-shop {
  min-width: 340px;
}
.time-report-table td.td-shop:hover {
  cursor: pointer;
  text-decoration: underline;
}
.time-report-table td.td-shop:hover svg path {
  fill: #2A84D5;
}
.time-report-table td.td-shop .td-shop-additional {
  position: absolute;
  font-weight: bold;
  right: 10px;
  bottom: 5px;
}
.time-report-table .td-diff,
.time-report-table .td-over {
  font-size: 12px;
  position: absolute;
  right: 10px;
  bottom: 0;
}
.time-report-table .td-diff {
  color: #f54d2e;
}
.time-report-table .td-over {
  color: #A0CD63;
}
.time-report-table .day-of-week {
  font-size: 12px;
  position: relative;
  top: 4px;
  font-weight: normal;
}
.time-report-table .day-of-num {
  margin-top: 12px;
  position: relative;
  top: 7px;
}
.time-report-table .ant-table-expanded-row > td {
  padding: 0 !important;
  border-bottom: 0;
}
.time-report-table .ant-table-expanded-row .ant-table {
  border: 0;
}
.time-report-table .ant-table-expanded-row table {
  border: 0 !important;
}
.time-report-table .ant-table-expanded-row .ant-table-container {
  border-left: 0;
}
.time-report-table .ant-table-expanded-row tr.ant-table-row td.ant-table-cell:last-child {
  border-right: 0;
}
.time-report-table table {
  border-top: 0;
}
.time-report-table table .ant-table-content {
  padding: 0;
}
.time-report-table .ant-table-content {
  padding-top: 20px;
}
.time-report-table .ant-table {
  border-top: 0;
}
.time-report-table .ant-input-number {
  width: 100%;
  border-radius: 3px;
  min-height: 28px;
  margin: -5px 0;
}
.time-report-table .ant-input-number input {
  text-align: center;
  height: 28px;
}
.time-report-table .ant-table-footer .ant-badge {
  margin-right: 20px;
}
.timetable-day-bullet {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 2px;
}
.shop-plans-timetable-day-bullet {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 2px;
}
@media screen and (max-width: 767px) {
  .td-bold {
    font-weight: bold;
  }
  .td-other {
    font-size: 13px;
    opacity: 0.9;
  }
  .table-visits .ant-table-wrapper thead {
    display: none;
  }
  .table-visits .ant-table-wrapper tr {
    display: flex;
    flex-direction: column;
  }
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.focus-category-color {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.mw100 {
  min-width: 100px;
}
.row-table {
  border-top: 1px solid #E9E9E9;
}
.row-table .ant-col {
  padding: 8px;
}
.row-table .ant-col:first-child {
  padding-left: 0;
}
.row-table .ant-col:last-child {
  padding-right: 0;
}
.row-table .ant-col strong {
  display: block;
}
.row-table .ant-col span {
  color: #828282;
}
@media screen and (min-width: 768px) {
  .table-mobile tr.ant-table-row .td-hide-desktop,
  .table-mobile .ant-table-thead .td-hide-desktop {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .table-mobile {
    padding: 10px;
  }
  .table-mobile thead,
  .table-mobile tr.ant-table-row .td-hide-mobile {
    display: none;
  }
  .table-mobile tr.ant-table-row {
    display: block;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 5px 0;
  }
  .table-mobile tr.ant-table-row:after,
  .table-mobile tr.ant-table-row:before {
    content: '';
    display: table;
  }
  .table-mobile tr.ant-table-row:after {
    clear: both;
  }
  .table-mobile tr.ant-table-row:first-child {
    border: 0;
  }
  .table-mobile tr.ant-table-row td {
    display: block;
    width: 100%;
    border: 0;
    padding: 0;
  }
  .table-mobile tr.ant-table-row td:not(:empty) {
    padding: 3px 0 !important;
  }
  .table-mobile tr.ant-table-row .td-inline {
    display: inline-block;
    width: auto;
    margin-right: 4px;
  }
  .table-mobile .ant-table {
    border-top: none;
    overflow-x: hidden;
  }
  .table-mobile .ant-table .ant-table-fixed {
    width: 100% !important;
  }
  .table-mobile.table-file tr.ant-table-row td {
    display: table-cell;
    width: auto;
  }
  .ant-table-thead > tr.ant-table-row-hover > td,
  .ant-table-tbody > tr.ant-table-row-hover > td,
  .ant-table-thead > tr:hover > td,
  .ant-table-tbody > tr:hover > td {
    background: transparent;
  }
  .card-body .table-mobile .ant-table-row {
    padding: 10px 15px 5px;
  }
}
.preview-modal img {
  max-width: 100%;
}
.search-modal.ant-modal {
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  overflow: hidden;
  max-width: 100vw;
}
.search-modal.ant-modal .ant-modal-body {
  padding: 0px;
}
.search-modal.ant-modal .ant-modal-content {
  border-radius: 0;
  height: 100vh;
  overflow: auto;
}
.search-modal.ant-modal .search-toolbar {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #F0F0F0;
}
.search-modal.ant-modal .search-toolbar .ant-input-search {
  width: calc(100% - 100px);
}
@media screen and (max-width: 767px) {
  .search-modal.ant-modal .search-toolbar {
    padding: 0 0 8px;
  }
}
.ant-modal {
  top: 30px;
}
.ant-modal .ant-modal-content .ant-form-item {
  margin-bottom: 14px;
}
.ant-modal .ant-table {
  width: calc(100% + 32px);
  margin: 0 -16px;
}
.ant-modal .ant-table-pagination {
  margin-bottom: 0;
}
.ant-modal .ant-steps {
  margin: -16px -16px 0;
  padding: 16px;
}
.ant-modal .ant-steps,
.ant-modal .ant-steps .ant-steps-title,
.ant-modal .ant-steps .ant-steps-head {
  background-color: #f7f7f7;
}
@media screen and (min-width: 768px) {
  .vertical-center-modal {
    text-align: center;
  }
  .vertical-center-modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }
  .vertical-center-modal .ant-modal {
    display: inline-block;
    vertical-align: middle;
    top: 0;
    text-align: left;
    padding-top: 24px;
  }
}
@media screen and (max-width: 767px) {
  .ant-select-dropdown {
    max-width: 250px !important;
  }
}
.crop-image-modal .cropped-preview {
  height: 235px;
  width: 235px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;
  border-radius: 50%;
  overflow: hidden;
}
.crop-image-modal .cropped-preview.cropped-preview-mobile {
  margin-left: 0;
  display: inline-block;
}
.crop-image-modal .crop-image-description {
  text-align: center;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .crop-image-modal .cropped-preview {
    display: block;
    margin: 10px auto 0;
  }
}
.coordinates-loader .ant-modal-body {
  padding-top: 45px;
}
.checkin-shop-info {
  margin: 10px 0 20px;
}
.checkin-shop-info .checkin-shop-info-name {
  font-weight: 600;
}
.checkin-shop-info .checkin-shop-info-name .ant-tag {
  margin-left: 5px;
}
.view-coordinates-icon {
  cursor: pointer;
  font-size: 15px;
  margin-left: 6px;
  position: relative;
  top: 1px;
}
.product-title {
  color: #324153;
  font-weight: 500;
}
.product-other {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  color: #595959;
  min-height: 20px;
}
.product-other .pull-right {
  display: flex;
  align-items: center;
}
.product-other .ant-input-number {
  margin-right: 5px;
}
.ant-message {
  z-index: 10000;
}
.customCarousel .ant-carousel,
.customCarousel .slick-slider,
.customCarousel .slick-list,
.customCarousel .slick-track {
  height: 100% !important;
}
.customCarousel .slick-slide > div:first-child {
  height: 100% !important;
}
.customCarousel .slick-slide > div:first-child div {
  height: 100% !important;
}
.customCarousel .slick-slide > div:first-child div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.menu {
  position: relative;
  padding: 0 15px;
  border-bottom: 1px solid #e9e9e9;
}
.menu .ant-menu {
  border-bottom: none;
}
.menu .menu-controls {
  margin-top: 6px;
}
@media screen and (max-width: 767px) {
  .menu .menu-controls {
    float: right;
  }
}
@media screen and (min-width: 768px) {
  .menu .menu-controls {
    position: absolute;
    top: 0;
    right: 15px;
  }
}
.menu .menu-controls button:not(:first-child) {
  margin-left: 10px;
}
.ant-tag-orange,
.ant-tag-red,
.ant-tag-green,
.ant-tag-blue,
.ant-tag-purple,
.ant-tag-geekblue,
.ant-tag-gold,
.ant-tag-cyan {
  color: #fff;
}
.ant-tag-orange {
  background: #EB3224;
  border-color: #EB3224;
}
.ant-tag-gold {
  background: #F6C242;
  border-color: #F6C242;
}
.ant-tag-red {
  background: #f54d2e;
  border-color: #f54d2e;
}
.ant-tag-green {
  background: #A0CD63;
  border-color: #A0CD63;
}
.ant-tag-blue {
  background: #2A84D5;
  border-color: #2A84D5;
}
.ant-tag-cyan {
  background: #ecccb2;
  border-color: #ecccb2;
}
.ant-tag-geekblue {
  background: #4BAEEB;
  border-color: #4BAEEB;
}
.ant-tag-purple {
  background: #68389A;
  border-color: #68389A;
}
.other-team {
  margin-left: 5px;
  position: relative;
  top: 1px;
  vertical-align: top;
  font-size: 14px;
}
.team-user-avatar {
  margin: 2px 0 2px 5px;
  display: inline-block;
  position: relative;
}
.team-user-avatar .ant-badge {
  vertical-align: inherit;
}
.team-user-avatar .ant-badge-dot {
  background: #A0CD63;
  right: -4px;
  overflow: inherit;
}
.team-user-avatar .ant-badge-dot:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #A0CD63;
  content: "";
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
}
.team-user-avatar.team-super:before {
  content: '';
  z-index: 5;
  display: inline-block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: -7px;
  right: -3px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.0733 5.1625L10.5394 4.50358L8.5126 0.394646C8.45725 0.282146 8.36618 0.191075 8.25367 0.135717C7.97153 -0.00356831 7.62867 0.112503 7.4876 0.394646L5.46082 4.50358L0.926889 5.1625C0.801889 5.18036 0.687603 5.23929 0.600103 5.32857C0.494321 5.4373 0.43603 5.58358 0.438039 5.73526C0.440048 5.88694 0.502192 6.03162 0.610817 6.1375L3.89117 9.33572L3.11617 13.8518C3.098 13.9568 3.10963 14.0649 3.14973 14.1637C3.18984 14.2625 3.25682 14.348 3.34308 14.4107C3.42935 14.4733 3.53144 14.5106 3.63778 14.5181C3.74413 14.5257 3.85047 14.5034 3.94475 14.4536L8.0001 12.3214L12.0555 14.4536C12.1662 14.5125 12.2947 14.5321 12.418 14.5107C12.7287 14.4571 12.9376 14.1625 12.884 13.8518L12.109 9.33572L15.3894 6.1375C15.4787 6.05 15.5376 5.93572 15.5555 5.81072C15.6037 5.49822 15.3858 5.20893 15.0733 5.1625V5.1625Z' fill='%23FFA500'/%3E%3C/svg%3E%0A");
  background-size: 12px;
}
.ant-radio-group {
  display: table;
  width: 100%;
}
.ant-radio-group .ant-radio-button-wrapper {
  display: table-cell;
  text-align: center;
  color: #2A84D5;
  border-color: #2A84D5;
}
.ant-radio-group .ant-radio-button-wrapper-checked {
  background: #2A84D5;
  color: #fff;
}
.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
}
.ant-popover-title {
  height: auto;
}
.title-popover {
  padding: 5px 0;
  display: block;
  line-height: normal;
}
.tag-popover {
  padding: 0 0 5px;
  display: block;
  line-height: normal;
}
.online-popover {
  color: #9fbd78;
}
.ant-popover-inner-content .anticon-mail,
.ant-popover-inner-content .anticon-phone {
  margin-right: 5px;
}
.ant-badge-status-processing {
  background-color: #A0CD63;
}
.ant-badge-status-processing:after {
  border-color: #A0CD63;
}
.badge-no-text .ant-badge-status-text {
  display: none;
}
.geo-icon {
  padding: 8px;
}
.no-geo-icon {
  position: relative;
  padding: 8px;
}
.no-geo-icon:after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 1px;
  background-color: #f54d2e;
  transform: rotate(50deg);
  top: 14px;
  left: 7px;
}
.no-geo-icon .anticon {
  color: #f54d2e;
  font-size: 16px;
}
.no-geo-icon + .anticon-environment {
  display: none;
}
.no-shop-icon {
  position: relative;
}
.no-shop-icon:after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 22px;
  height: 1px;
  background-color: #f54d2e;
  transform: rotate(50deg);
  top: 9px;
  left: -3px;
}
.no-shop-icon .anticon-shop {
  color: #f54d2e;
  font-size: 14px;
  margin-right: 4px;
}
.input-coordinates .map-icon {
  cursor: pointer;
  font-size: 14px;
  padding-top: 6px;
}
.map-popover {
  width: 600px;
  min-height: 400px;
}
@media screen and (max-width: 767px) {
  .map-popover {
    width: 300px;
    min-height: 250px;
  }
}
.map-popover .ant-popover-inner-content {
  min-height: 366px;
}
@media screen and (max-width: 767px) {
  .map-popover .ant-popover-inner-content {
    min-height: 216px;
  }
}
.map-popover .map-title {
  position: relative;
}
.map-popover .close {
  position: absolute;
  right: 0;
  font-size: 16px;
  top: 1px;
  cursor: pointer;
}
.map-popover .map-container {
  height: 350px;
}
@media screen and (max-width: 767px) {
  .map-popover .map-container {
    height: 200px;
  }
}
.map-popover .map-element {
  height: 320px;
}
@media screen and (max-width: 767px) {
  .map-popover .map-element {
    height: 170px;
  }
}
.map-popover .map-loading-elem {
  height: 100px;
}
.map-popover .map-buttons {
  margin-top: 5px;
}
.map-popover .map-searchbox {
  width: 300px;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .map-popover .map-searchbox {
    margin-left: 5px;
    width: 170px;
    margin-top: 10px;
  }
}
.pac-container {
  z-index: 9999;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  left: 0;
  right: 0;
}
.no-active-checkin {
  padding: 20px 0;
}
.checkin {
  background: #EFF2F5;
}
.checkin .fab-btn {
  background-color: #2a83d4;
  border-color: #2a83d4;
}
.checkin .shop-title h3 {
  display: inline-block;
}
.checkin .shop-title button {
  float: right;
  margin-top: 9px;
}
.shop-checkin-progress .ant-progress-text {
  font-size: 12px;
}
