.preview-modal {
    img {
        max-width: 100%;
    }
}

.search-modal.ant-modal {
    top: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    overflow: hidden;
    max-width: 100vw;

    .ant-modal-body {
        padding: 0px;
    }

    .ant-modal-content {
        border-radius: 0;
        height: 100vh;
        overflow: auto;
    }

    .search-toolbar {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border-bottom: 1px solid #F0F0F0;
        .ant-input-search {
            width: calc(100% - 100px);
        }
        @media @mobile {
            padding: 0 0 8px;
        }
    }
}

.ant-modal {
    top: 30px;

    .ant-modal-content {
        .ant-form-item {
            margin-bottom: 14px;
        }
    }

    .ant-table {
        width: calc(~'100% + 32px');
        margin: 0 -16px;
    }

    .ant-table-pagination {
        margin-bottom: 0;
    }

    .ant-steps {
        margin: -16px -16px 0;
        padding: 16px;

        &, .ant-steps-title, .ant-steps-head {
            background-color: #f7f7f7;
        }
    }
}

@media @desktop {
    .vertical-center-modal {
        text-align: center;
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            width: 0;
        }
        .ant-modal {
            display: inline-block;
            vertical-align: middle;
            top: 0;
            text-align: left;
            padding-top: 24px;
        }
    }
}

@media @mobile {
    .ant-select-dropdown {
        max-width: 250px !important;
    }
}

.crop-image-modal {
    .cropped-preview {
        height: 235px;
        width: 235px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 10px;
        border-radius: 50%;
        overflow: hidden;

        &.cropped-preview-mobile {
            margin-left: 0;
            display: inline-block;
        }
    }
    .crop-image-description {
        text-align: center;
        margin-bottom: 20px;
    }
}
@media @mobile {
    .crop-image-modal .cropped-preview {
        display: block;
        margin: 10px auto 0;
    }
}

.coordinates-loader {
    .ant-modal-body {
        padding-top: 45px;
    }
}

.checkin-shop-info {
    margin: 10px 0 20px;
    .checkin-shop-info-name {
        font-weight: 600;
        .ant-tag {
            margin-left: 5px;
        }
    }
}

.view-coordinates-icon {
    cursor: pointer;
    font-size: 15px;
    margin-left: 6px;
    position: relative;
    top: 1px;
}

.product-title {
    color: @main-color;
    font-weight: 500;
}

.product-other {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    color: #595959;
    min-height: 20px;
    .pull-right {
        display: flex;
        align-items: center;
    }
    .ant-input-number {
        margin-right: 5px;
    }
}

.ant-message {
    z-index: 10000;
}

.customCarousel .ant-carousel,
.customCarousel .slick-slider,
.customCarousel .slick-list,
.customCarousel .slick-track {
  height: 100% !important;
}

.customCarousel .slick-slide > div:first-child {
  height: 100% !important;
  div {
    height: 100% !important;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
}