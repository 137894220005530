.detail-card {
  .head-bar {
    display: table;
    width: 100%;
    margin: 0;
    color: #5a5a5a;
    background: #fafafa;
    padding: 15px;
    border-bottom: 1px solid #e6e6e6;
    line-height: 100%;
    font-weight: 400;
    font-size: 1.15rem;

    .back-block, .avatar-block, .title-block, .edit-block {
      display: table-cell;
      vertical-align: middle;
    }

    .avatar-block {
      width: 60px;
      line-height: 0;
    }

    .title-inscription {
      font-size: 11px;
      display: inline-block;
      line-height: 20px;
    }

    .back-block {
      width: 40px;
    }

    .title-text {
      font-size: 1.15rem;
      line-height: 100%;
      font-weight: 400;
      display: inline-block;
      padding: 4px 0;
    }

    .edit-block {
      text-align: right;

      button {
        margin-left: 10px;
      }
    }
  }

  .info-head {
    background: #f7f7f7;
    padding: 0 15px;
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid #e9e9e9;

    .anticon {
      margin-right: 8px;
    }
  }

  .info-body {
    padding: 15px;

    .ant-row {
      margin-bottom: 10px;
    }
  }

  .head-spinner {
    height: 79px;
    padding-top: 30px;

    .spinner {
      padding: 0;
    }
  }
}

.user-photo-upload {
  .ant-upload-drag-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    .user-photo-image {
      width: 120px;
      height: 120px;
      background-position: center;
      background-size: cover;
      position: relative;
      border-radius: 50%;
    }
    .anticon-plus {
      margin-top: 0px;
    }
  }
  .ant-upload-disabled {
    cursor: default;
  }
}

.user-photo-uploaded-container {
  position: relative;
  height: 120px;
  width: 120px;
  .ant-upload {
    border-radius: 50%;
  }
  .ant-upload, .ant-upload-drag .ant-upload.ant-upload-btn {
    padding: 0;
  }
  .hover-black {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: all .2s;
    border-radius: 50%;
  }
  &:hover {
    .hover-black {
      opacity: .5;
    }
  }
  .user-photo-uploaded-controls {
    position: absolute;
    left: 0;
    height: 20px;
    width: 100%;
    opacity: 0;
    top: 50px;
    text-align: center;
    transition: all .2s;
    a, .span-link {
      margin: 5px;
      .anticon {
        color: #fff;
        font-size: 20px;
      }
    }
  }
  &:hover {
    .user-photo-uploaded-controls {
      opacity: 1;
    }
  }
}

.person-photo {
  background-position: center;
  background-size: cover;
}
