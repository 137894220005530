.menu {
    position: relative;
    padding: 0 15px;
    border-bottom: 1px solid #e9e9e9;

    .ant-menu {
        border-bottom: none;
    }

    .menu-controls {
        margin-top: 6px;

        @media @mobile {
            float: right;
        }

        @media @desktop {
            position: absolute;
            top: 0;
            right: 15px;
        }

        button:not(:first-child) {
            margin-left: 10px;
        }
    }
}
