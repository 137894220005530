body {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
}

.logo {
    display: inline-block;
    height: 26px;
    width: 125px;
    background-size: auto;
    margin: 20px 0 0 60px;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9ItCh0LvQvtC5XzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTgwIDM0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxODAgMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojRkZGRkZGO308L3N0eWxlPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNjgsMC42bC01LjYsNy45VjAuNmgtMTAuMXYzMi43aDEwLjF2LTIxaDkuMkwxODAsMC42SDE2OHogTTEyOS40LDI0LjZjLTQuMywwLTcuNC0zLjItNy40LTcuNmMwLTQuNCwzLjItNy43LDcuNC03LjdjNC4yLDAsNy40LDMuMyw3LjQsNy43QzEzNi45LDIxLjQsMTMzLjcsMjQuNiwxMjkuNCwyNC42eiBNMTI5LjQsMGMtOS44LDAtMTcuNSw3LjUtMTcuNSwxN2MwLDkuNSw3LjcsMTcsMTcuNSwxN2M5LjgsMCwxNy41LTcuNSwxNy41LTE3QzE0Nyw3LjUsMTM5LjMsMCwxMjkuNCwweiBNOTcuNywyNC42Yy00LjYsMC03LjgtMy4xLTcuOC03LjZjMC00LjQsMy4zLTcuNyw3LjYtNy43YzIuMiwwLDQuNCwwLjksNiwyLjVsNS41LTcuN0MxMDUuOSwxLjUsMTAxLjgsMCw5Ny41LDBjLTkuOSwwLTE3LjcsNy41LTE3LjcsMTdjMCw5LjcsNy42LDE3LDE3LjcsMTdjNC44LDAsOC44LTEuNCwxMi4xLTQuM2wtNS41LTcuOEMxMDIuNiwyMy4zLDEwMC40LDI0LjYsOTcuNywyNC42TDk3LjcsMjQuNnogTTYxLjUsMGMtMi45LDAtNy43LDEuNC05LjQsMy41VjAuNmgtOS44djMyLjdoMTAuMVYxMi42YzEuMS0xLjQsMy43LTMuMyw2LjgtMy4zYzEuNywwLDMuMiwwLjUsNC4xLDEuNWMwLjgsMC44LDEuNiwyLDEuNiw1LjJ2MTcuM0g3NXYtMjFDNzUsNC4xLDY5LDAsNjEuNSwwTDYxLjUsMHogTTE3LjUsMjQuNmMtNC4zLDAtNy40LTMuMi03LjQtNy42YzAtNC40LDMuMi03LjcsNy40LTcuN2M0LjIsMCw3LjQsMy4zLDcuNCw3LjdDMjQuOSwyMS40LDIxLjgsMjQuNiwxNy41LDI0LjZ6IE0yNC41LDMuNUMyMi40LDEsMTkuMywwLDE2LjIsMEM3LjEsMCwwLDcuNSwwLDE3YzAsNC4zLDEuNSw4LjMsNC4yLDExLjRjMywzLjUsNy43LDUuNSwxMi41LDUuNWMyLjksMCw2LjItMS4yLDcuOS0zLjR2Mi44aDkuOFYwLjZoLTkuOFYzLjVMMjQuNSwzLjV6Ii8+PC9zdmc+);
}

#root {
    height: 100%;

    .left-menu {
        overflow: auto;
        height: 100vh;
        position: fixed;
        left: 0;
        box-shadow: none;
        @media @desktop {
            position: relative;
        }
        &-wrapper {
            background: #304156;
        }
        .ant-layout-sider-children {
            position: fixed;
            top: 0;
            bottom: 48px;
            width: 240px;
            overflow-y: auto;
            overflow-x: hidden;
            transition: width 0.2s;
        }
        &.ant-layout-sider-collapsed {
            .ant-layout-sider-children {
                width: 71px;
                @media @mobile {
                    width: 0;
                }
            }
        }
    }

    .layout-wrapper {
        min-height: 100vh;
    }

    & > .spinner {
        margin: 0;
        position: relative;
        height: 100vh;
        line-height: 100vh;

        .ant-spin {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.spinner {
    padding: 10px 0;
}

.fab-btn {
    position: fixed !important;
    bottom: 40px;
    right: 40px;
    width: 60px !important;
    height: 60px !important;
    z-index: 2;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);

    @media @mobile {
        bottom: 10px;
        right: 20px;
    }
}

.task-form {
    label:not(.ant-radio-button-wrapper) {
        display: block;
    }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
    padding: 8px;
}

.task-table-text {
    overflow: hidden;
    max-height: 35px;
    display: inline-block;
    margin-bottom: -5px;

    h1, h2, h3, h4, h5, h6, p, strong, em, u {
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        text-decoration: none;
        color: #108ee9;
    }
}

.task-text-block {
    ul, ol {
        padding-left: 40px;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }
}

.text-center {
    text-align: center;
}
.text-bold{
    font-weight: bold;
}

.ant-row [class^=ant-col-] {
    margin-bottom: 5px;
}

body a {
    color: @blue;
}

td.timetable-day-column {
    width: 35px;
    padding: 0 !important;
    text-align: center;

    & > .span-link {
        padding: 13.5px 8px;
        display: inline-block;
        width: 100%;
        color: #fff;
        font-size: 10px;
    }
}

.ant-table .ant-table-container {
    .timetable-gold {
        background: @gold;
    }

    .timetable-orange {
        background: @orange;
    }

    .timetable-yellow {
        background: @yellow;
        color: #000;
    }

    .timetable-green {
        background: @green;
    }

    .timetable-blue {
        background: @blue;
    }

    .timetable-geekblue {
        background: @geekblue;
    }

    .timetable-cyan {
        background: @cyan;
    }

    .timetable-red {
        background: @red;
    }

    .timetable-dred {
        background: @dred;
    }

    .timetable-black {
        background: @black;
        color: #fff;
    }

    .timetable-beige {
        background: @beige;
    }

    .timetable-purple {
        background: @purple;
    }

    .timetable-grey {
        background: @grey;
    }
}

.ant-table .ant-table-header {
    .th-timetable-gold {
        background: @gold;
    }

    .th-timetable-orange {
        background: @orange;
        color: #fff;
    }

    .th-timetable-yellow {
        background: @yellow;
        color: #000;
    }

    .th-timetable-green {
        background: @green;
    }

    .th-timetable-blue {
        background: @blue;
    }

    .th-timetable-geekblue {
        background: @geekblue;
    }

    .th-timetable-cyan {
        background: @cyan;
    }

    .th-timetable-red {
        background: @red;
        color: #fff;
    }

    .th-timetable-dred {
        background: @dred;
        color: #fff;
    }

    .th-timetable-black {
        background: @black;
        color: #fff;
    }

    .th-timetable-beige {
        background: @beige;
    }

    .th-timetable-purple {
        background: @purple;
    }

    .th-timetable-grey {
        background: @grey;
    }
}

.ant-badge-status-orange {
    background: @orange;
}

.ant-badge-status-gold {
    background: @gold;
}

.ant-badge-status-yellow {
    background: @yellow;
    color: #000;
}

.ant-badge-status-green {
    background: @green;
}

.ant-badge-status-blue {
    background: @blue;
}

.ant-badge-status-geekblue {
    background: @geekblue;
}

.ant-badge-status-cyan {
    background: @cyan;
}

.ant-badge-status-red {
    background: @red;
}

.ant-badge-status-dred {
    background: @dred;
    color: #fff;
}

.ant-badge-status-black {
    background: @black;
    color: #fff;
}

.ant-badge-status-beige {
    background: @beige;
}

.ant-badge-status-purple {
    background: @purple;
}

.ant-badge-status-grey {
    background: @grey;
}

.w-b-normal {
    word-break: normal;
}

.timetable {
    .ant-table-tbody {
        line-height: 1;
    }

    .ant-table-thead > tr > th {
        padding: 16px 8px;
    }

    .ant-table-tbody > tr > td {
        padding: 8px;
    }

    .ant-table-thead > tr > th:first-child,
    .ant-table-tbody > tr > td:first-child {
        padding-left: 15px;
        min-width: 150px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.ql-tooltip {
    top: 0 !important;
    left: 0 !important;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.text-right {
    text-align: right;
}

.pull-clear {
    clear: both;
}

.bg-color-success{
    background-color: @green;
}
.bg-color-info{
    background-color: @blue;
}
.bg-color-warning{
    background-color: @orange;
}
.bg-color-primary{
    background-color: @primary;
}
.p15{
    padding: 15px;
}
.pt0{
    padding-top: 0;
}
.pr10{
    padding-right: 10px;
}
.pr15{
    padding-right: 15px;
}
.ml5 {
    margin-left: 5px;
}
.mr15 {
    margin-right: 15px;
}
.ml10 {
    margin-left: 10px;
}
.mt15 {
    margin-top: 15px;
}
.mb10 {
    margin-bottom: 10px;
}

.mb15 {
    margin-bottom: 15px;
}
.bold {
    font-weight: 600;
}

.color-red {
    color: #f04134;
}

.hide {
    display: none !important;
}

.hide-mobile {
    @media @mobile {
        display: none !important;
    }
}

.hide-desktop {
    @media @desktop {
        display: none !important;
    }
}

.font-small {
    font-size: 0.9em;
}

.mw250 {
    max-width: 250px;
}

.news-item, .news-full-header-text {
    word-break: break-word;
}

.layout-wrap {
    background: #EFF2F5;
    @media @mobile {
        padding: 16px 16px 24px;
        min-height: 100%;
    }
    @media @xs {
        padding: 16px 8px 24px;
    }
}

.sale-item {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #3D4D65;
    padding: 8px 0;
    position: relative;
    cursor: pointer;
    .price {
        color: #9FBD78;
        white-space: nowrap;
    }
    .sum {
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
    }
    .arrow-right {
        position: absolute;
        right: -13px;
        margin-top: -8px;
        width: 12px;
        height: 12px;
        top: 50%;
    }
}

.price-item {
    text-align: right;
    padding-left: 8px;
}

.sale-list {
    background: @main-color;
    padding: 8px 16px 16px;
    color: #fff;
    border-radius: 8px;
    margin-bottom: 16px;
    .sale-item:first-child {
        border: 0;
    }
    .shop-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #8F8F8F;
        margin-top: 8px;
        font-size: 12px;
        line-height: 14px;
        .ant-btn {
            color: #8F8F8F;
        }
    }
}

.line-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 8px;
    .title-item {
        margin: 0;
    }
}

.title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: @main-color;
    margin-bottom: 16px;
}

.title-item {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: @main-color;
    margin: 20px 0 6px;
    &-sub {
        margin-top: -2px;
        font-size: 14px;
        line-height: 16px;
        color: #595959;
        font-weight: normal;
    }
}

.total-sum {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #9FBD78;
}

.title-count {
    margin-right: 8px;
    font-size: 16px;
    line-height: 19px;
    color: #324153;
    font-weight: 500;
}

.arrow-back {
    display: none;
}

.plan-head {
    display: flex;
    margin-bottom: 0;
    font-size: 20px;
    @media @xs {
        font-size: 18px;
    }
    .plan-title {
        white-space: nowrap;
    }
}

.text-nowrap {
    white-space: nowrap;
}

@media @mobile {
    html {
        background: #e8ebee;
    }
    .mt15 {
        .ant-spin-spinning {
            width: 100%;
        }
    }
    .ant-spin-spinning {
        position: absolute;
        top: 50%;
    }
    .arrow-back {
        display: inline-block;
        position: fixed;
        top: 8px;
        left: 0;
        z-index: 1020;
        border: 0;
        svg {
            width: 24px;
        }
    }
}
