.ant-radio-group{
  display: table;
  width: 100%;

  .ant-radio-button-wrapper{
    display: table-cell;
    text-align: center;
    color: @blue;
    border-color: @blue;
  }

  .ant-radio-button-wrapper-checked{
    background: @blue;
    color: #fff;
    &:not(.ant-radio-button-wrapper-disabled):hover {
      color: #fff;
    }
  }
}
