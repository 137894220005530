.filters-bar {
    clear: both;
    padding: 10px 15px;

    .controls-block {
        text-align: right;
        padding-right: 10px;

        @media @mobile {
            text-align: left;
        }
    }

    .ant-form-item {
        margin-bottom: 0 !important;
    }

    button {
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }

    .filters-additional {
        text-align: right;
        padding-bottom: 10px;

        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .mobile-filter-button {
        @media @mobile {
            float: right;
            margin-top: -3px;
            width: auto;
        }

        text-align: right;
    }

    .ant-collapse {
        .ant-collapse-item {
            border-bottom: 0;

            .ant-collapse-header {
                background-color: transparent !important;
                padding: 0 0 15px 25px;

                .ant-collapse-arrow {
                    position: absolute;
                    line-height: 22px;
                    left: 5px;
                }
            }

            .ant-collapse-content, .ant-collapse-content-box {
                overflow: visible;
                border-top: 0;
                padding: 0;
                margin-top: 5px;
            }
        }
    }
    .filters-row {
        .ant-col {
            margin-bottom: 4px;
        }
    }
}
@media @mobile {
    .filters-bar {
        padding: 0;
    }
    .range-sales {
        top: 121px!important;
        .ant-picker-panel-container .ant-picker-panels {
            flex-direction: column;
        }
    }
}
