@media @desktop {
    .table-mobile {
        tr.ant-table-row, .ant-table-thead {
            .td-hide-desktop {
                display: none;
            }
        }
    }
}

@media @mobile {
    .table-mobile {
        thead, tr.ant-table-row .td-hide-mobile {
            display: none;
        }

        tr.ant-table-row {
            display: block;
            width: 100%;
            border-top: 1px solid #e9e9e9;
            padding: 5px 0;
            &:after,&:before{
                content: '';
                display: table;
            }
            &:after {
                clear: both;
            }
            &:first-child{
                border: 0;
            }
            td {
                display: block;
                width: 100%;
                border: 0;
                padding: 0;

                &:not(:empty) {
                    padding: 3px 0 !important;
                }
            }

            .td-inline {
                display: inline-block;
                width: auto;
                margin-right: 4px;
            }
        }

        padding: 10px;

        .ant-table {
            border-top: none;
            overflow-x: hidden;

            .ant-table-fixed {
                width: 100% !important;
            }
        }

        &.table-file{
            tr.ant-table-row {
                td{
                    display: table-cell;
                    width: auto;
                }
            }
        }
    }

    .ant-table-thead > tr.ant-table-row-hover > td,
    .ant-table-tbody > tr.ant-table-row-hover > td,
    .ant-table-thead > tr:hover > td,
    .ant-table-tbody > tr:hover > td {
        background: transparent;
    }

    .card-body {
        .table-mobile {
            .ant-table-row {
                padding: 10px 15px 5px;
            }
        }
    }
}
