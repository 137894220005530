.ant-alert {
    &.ant-alert-no-icon.text-center {
        padding: 8px;
    }
}

.alert-text {
    display: inline-flex;
    align-items: center;
    color: @red;
    font-size: 14px;
    .anticon {
        margin-right: 8px;
    }
}
