.ant-modal-wrap {
    z-index: 1030;
    .ant-select, .ant-cascader-picker, .ant-input-number {
        width: 100%;
    }
}

.ant-input-search, .ant-calendar-picker {
    width: 100%;
}

.ant-select.shop-select {
    width: 170px;
    .ant-select-selection--single {
        height: 34px !important;
        padding-top: 3px;
    }
    .ant-select-selection-selected-value {
        display: none !important;
    }
    .ant-select-selection__placeholder {
        display: block !important;
        color: rgba(0, 0, 0, 0.65);
        overflow: visible;
        white-space: normal;
        width: 100%;
        text-align: center;
    }
    .ant-select-arrow {
        display: none;
    }
}

.shop-select-option {
    .shop-select-name {
        font-weight: 600;
    }
    .shop-select-distance {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}

.ant-select-dropdown {
    @media @desktop {
        .ant-select-dropdown-menu-item.shop-select-option {
            padding-right: 100px;
        }
    }
}

.ant-select-dropdown-menu-item-selected.shop-select-option .shop-select-address {
    font-weight: 400 !important;
}

.ant-form-item {
    flex-direction: column;
    .ant-form-item-label {
        text-align: left;
        & > label::after {
            content: none;
        }
    }
    .ant-form-item-explain {
        min-height: auto;
    }
}

.ant-form-item.form-item-inline {
    flex-direction: row;
}

.sync-row {
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;

    .ant-col > b {
        text-transform: capitalize;
        line-height: 32px;
    }
}

.ant-form-item-control {
    line-height: inherit;
}

.shop-select-option {
    overflow: hidden;
    text-overflow: ellipsis;
}

.span-link {
    color: @blue;
    cursor: pointer;
}

.ant-form-item-control-input-content {
    .ant-picker {
        width: 100%;
    }
}

.ant-checkbox-group {
    display: flex;
    flex-direction: column;
}

.rdw-editor-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 8px;
}

@media @mobile {
    .ant-modal:not(.text-center) {
        top: 0!important;
        max-width: 100%;
        margin: 0;
        padding: 0;
        min-height: 100vh;
        .ant-modal-content {
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 0;
            .ant-modal-header {
                padding: 16px;
            }
            .ant-modal-body {
                min-height: calc(100vh - 55px - 59px);
                padding: 16px;
            }
        }
    }
}
