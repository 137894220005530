.sales-form {
    padding: 15px;
    @media @mobile {
        .arrow-back {
            position: fixed;
        }
    }
}

.product-list {
    .ant-row {
        margin: 0 16px;
        padding: 8px 0;
        border-bottom: 1px solid #F0F0F0;
        cursor: pointer;
        @media @mobile {
            margin: 0;
        }
    }

    padding-bottom: 46px;
    position: relative;
}
.product-list-pagination {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 6px;
}

.sales-form-product-list {
    & > .ant-row {
        padding: 8px 0;
        border-bottom: 1px solid #F0F0F0;

        .ant-form-item {
            display: inline-block;
            margin-bottom: 0;
        }

        .product-remove {
            margin-right: -16px;
            color: @red;
        }

        .ant-typography {
            padding: 0 6px;
            line-height: 32px;
        }
    }

    .sales-form-product-list-total {
        padding: 16px 0;
    }
}
