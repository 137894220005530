.no-active-checkin {
    padding: 20px 0;
}

.checkin {
    background: #EFF2F5;
    .fab-btn {
        background-color: #2a83d4;
        border-color: #2a83d4;
    }
    .shop-title {
        h3 {
            display: inline-block;
        }

        button {
            float: right;
            margin-top: 9px;
        }
    }
}

.shop-checkin-progress .ant-progress-text {
    font-size: 12px;
}
