.ant-avatar.file-pdf {
  background-color: #f44336;
}

.ant-avatar.file-excel {
  background-color: #4caf50;
}

.ant-avatar.file-text {
  background-color: #2196f3;
}

.ant-avatar.file-ppt {
  background-color: #ff9800;
}
