.ant-badge-status-processing {
  background-color: @green;
  &:after{
    border-color: @green;
  }
}
.badge-no-text{
  .ant-badge-status-text{
    display: none;
  }
}
