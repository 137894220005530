.ant-btn {
    &.ant-btn-success {
        background-color: @success-color;
        border-color: @success-color;
        color: #fff;

        &:hover, &:focus {
            background-color: @success-color-focus;
            border-color: @success-color-focus;
            color: #fff;
        }
    }
    @media @mobile {
        height: 38px;
    }
}

.action-icon {
    cursor: pointer;
    font-size: 16px;
    opacity: .6;
    transition: opacity .3s;
    &:hover {
        opacity: 1;
    }
}

.primary-dashed {
    color: @blue;
    border-color: @blue;
}

.ant-btn.photo-button {
    display: block;
    height: auto !important;
    @media @mobile {
        font-size: 11px;
        padding: 4px 8px;
        width: 100px;
        min-height: 100px;
    }
}
