.input-coordinates {
  .map-icon {
    cursor: pointer;
    font-size: 14px;
    padding-top: 6px;
  }
}

.map-popover {
  width: 600px;
  min-height: 400px;

  @media @mobile {
    width: 300px;
    min-height: 250px;
  }

  .ant-popover-inner-content {
    min-height: 366px;

    @media @mobile {
      min-height: 216px;
    }
  }

  .map-title {
    position: relative;
  }

  .close {
    position: absolute;
    right: 0;
    font-size: 16px;
    top: 1px;
    cursor: pointer;
  }

  .map-container {
    height: 350px;

    @media @mobile {
      height: 200px;
    }
  }

  .map-element {
    height: 320px;

    @media @mobile {
      height: 170px;
    }
  }

  .map-loading-elem {
    height: 100px;
  }

  .map-buttons {
    margin-top: 5px;
  }

  .map-searchbox {
    width: 300px;
    margin-top: 10px;

    @media @mobile {
      margin-left: 5px;
      width: 170px;
      margin-top: 10px;
    }
  }
}

.pac-container {
  z-index: 9999;
}
