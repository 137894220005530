.ql-editor  {
    height: 160px;
}

.ql-viewer.ql-editor {
    height: auto;
    padding: 0;

    > * {
        cursor: inherit;
    }

    img {
        max-width: 100%;
    }

    a {
        text-decoration: underline;
    }
}
