.documents {
  .ant-avatar{
    .anticon {
      padding-left: 2px;
    }
  }

  .ant-table-body td:first-child {
    text-align: center;
    width: 77px;
  }

  .anticon-delete {
    cursor: pointer;
  }
}

.table-file {
  .ant-table-thead {
    tr {
      th:first-child {
        width: 60px;
      }
    }
  }
  @media @mobile {
    &.table-mobile {
      padding: 0;
    }
    thead {
      display: none;
    }
    .ant-table {
      background: transparent;
      colgroup {
        display: none;
      }
      tr.ant-table-row {
        background: @main-color;
        border-radius: 8px;
        margin-bottom: 8px;
        padding: 8px 12px;
        a {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #fff;
        }
        &:hover > td {
          background: transparent;
        }
      }
    }
  }
  .ant-avatar {
    display: inline-flex;
    align-items: center;
    background: transparent;
    border-radius: 0;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .anticon-file-unknown {

  }
}
