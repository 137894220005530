.ant-popover-title{
  height: auto;
}
.title-popover{
  padding: 5px 0;
  display: block;
  line-height: normal;
}
.tag-popover{
  padding: 0 0 5px;
  display: block;
  line-height: normal;
}
.online-popover{
  color: #9fbd78;
}

.ant-popover-inner-content {
  .anticon-mail, .anticon-phone {
    margin-right: 5px;
  }
}
