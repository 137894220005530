.other-team {
  margin-left: 5px;
  position: relative;
  top: 1px;
  vertical-align: top;
  font-size: 14px;
}
.team-user-avatar{
  margin: 2px 0 2px 5px;
  display: inline-block;
  position: relative;
  .ant-badge{
    vertical-align: inherit;
  }
  .ant-badge-dot {
    background: @green;
    right: -4px;
    overflow: inherit;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid @green;
      content: "";
      -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
      animation: antStatusProcessing 1.2s infinite ease-in-out;
    }
  }
  &.team-super{
    &:before {
      content: '';
      z-index: 5;
      display: inline-block;
      width: 12px;
      height: 12px;
      position: absolute;
      top: -7px;
      right: -3px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.0733 5.1625L10.5394 4.50358L8.5126 0.394646C8.45725 0.282146 8.36618 0.191075 8.25367 0.135717C7.97153 -0.00356831 7.62867 0.112503 7.4876 0.394646L5.46082 4.50358L0.926889 5.1625C0.801889 5.18036 0.687603 5.23929 0.600103 5.32857C0.494321 5.4373 0.43603 5.58358 0.438039 5.73526C0.440048 5.88694 0.502192 6.03162 0.610817 6.1375L3.89117 9.33572L3.11617 13.8518C3.098 13.9568 3.10963 14.0649 3.14973 14.1637C3.18984 14.2625 3.25682 14.348 3.34308 14.4107C3.42935 14.4733 3.53144 14.5106 3.63778 14.5181C3.74413 14.5257 3.85047 14.5034 3.94475 14.4536L8.0001 12.3214L12.0555 14.4536C12.1662 14.5125 12.2947 14.5321 12.418 14.5107C12.7287 14.4571 12.9376 14.1625 12.884 13.8518L12.109 9.33572L15.3894 6.1375C15.4787 6.05 15.5376 5.93572 15.5555 5.81072C15.6037 5.49822 15.3858 5.20893 15.0733 5.1625V5.1625Z' fill='%23FFA500'/%3E%3C/svg%3E%0A");
      background-size: 12px;
    }
  }
}
